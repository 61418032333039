import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  getMyRequestsRows,
  setColaboratorAppeal,
  changeRequestStatus,
} from "../actions";
import {
  loading,
  getMyRequests,
  getDateFormat,
  getUsedDates,
  getLocale,
  getDecimalSeparator,
  getFireCalculationData,
  getRaiseCalculationData,
  getColaborators,
  getRecipients,
} from "../selectors";
import { getLoginResponse, getTheme } from "@icarius-pages/login/selectors";
import { getAppColor, getUserData } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import { LocalAtmIcon, WorkOffIcon } from "@icarius-icons";
import RequestDialog from "./modalRequests/RequestDialog";
import SecondGridDialog from "./secondGridDialog";
import CancelDialog from "./cancelDialog";
import ReplyDialog from "../../myPending/components/dialogs/replyDialog";
import { requestsIds } from "@icarius-utils/requestsIds";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { openDialogAction } from "@icarius-common/dialog/actions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

class MyRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: this,
      typeToOpen: "",
      includesHistory: false,
      currentRequestWorkflow: [],
      secondGridDialogIsOpen: false,
      cancelDialogIsOpen: false,
      answerDialogIsOpen: false,
      myVacationsDialogIsOpen: false,
      isReadOnly: false,
    };
  }

  componentDidMount() {
    if (this.props.userData.level === "M") {
      this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
    }
  }

  handleOpenRequest = (type) => {
    this.setState({ typeToOpen: type });
  }

  handleCloseRequest = () => {
    this.setState({ typeToOpen: "" });
  };

  handleNoRecipient = () => {
    this.props.dispatch(openDialogAction({
      msg: "No se encontró a ningún receptor para la atención de su solicitud. La misma no puede enviarse. Informe sobre esta situación a Recursos Humanos",
      title: getLocalizedString("atention"),
      acceptOnly: true
    }
    ))
  }

  render() {
    const {
      myRequestsRows,
      loadingRows,
      exportingGrid,
      color,
      dateFormat,
      locale,
      raiseCalculationData,
      fireCalculationData,
      colaborators,
      recipients,
    } = this.props;

    const item1 = () =>
      <CustomIconButton
        title={getLocalizedString("disengagementTitle")}
        onClick={() => this.handleOpenRequest(requestsIds.id_type.FR)}
      >
        <WorkOffIcon />
      </CustomIconButton>

    const item2 = () =>
      <CustomIconButton
        title={getLocalizedString("payRiseTitle")}
        onClick={() => this.handleOpenRequest(requestsIds.id_type.RS)}
      >
        <LocalAtmIcon />
      </CustomIconButton>

    const helpItems = [
      <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Solicitudes Colaborador.pdf"} text={getLocalizedString("requestsColabInstructivo")} type={"file"} />,
      <MenuItemWithIcon key={"2"} value={"https://www.youtube.com/embed/8fj60nDAJ4A"} text={getLocalizedString("requestsTitle")} type={"video"} />,
    ]

    return (
      <CommonPage
        menuItems={[item1, item2]}
        helpItems={helpItems}
        rowData={myRequestsRows}
        title={getLocalizedString("myRequests")}
        gridTitle={getLocalizedString("myRequests")}
        locale={locale}
        dateFormat={dateFormat}
        columnDefPage={paths.myRequestsManager}
        handleRowClick={this.handleRowClick}
        isLoading={loadingRows || exportingGrid}
        hasHelp
        hasExpand
        hasSelectAll
        hasSwitch
        switchValue={this.state.includesHistory}
        handleSwitchClick={this.handleSwitchClick}
        switchLabelOn={getLocalizedString("requestsInHistory")}
        switchLabelOff={getLocalizedString("requestsInProgress")}
      >
        {
          this.state.secondGridDialogIsOpen && !loadingRows && !exportingGrid &&
          <SecondGridDialog
            open={this.state.secondGridDialogIsOpen}
            data={this.state.currentRequestWorkflow}
            dateFormat={dateFormat}
            locale={locale}
            handleOpenAnswerDialog={this.handleOpenAnswerDialog}
            handleOpenCancelDialog={this.handleOpenCancelDialog}
            handleOpenViewDialog={this.handleOpenViewDialog}
            handleClose={this.handleCloseSecondGridDialog}
          />
        }
        {
          this.state.cancelDialogIsOpen &&
          <CancelDialog
            open={this.state.cancelDialogIsOpen}
            code={this.state.currentRequestWorkflow[0].code}
            handleCloseDialog={this.handleCloseCancelDialog}
            handleAccept={this.handleCancelRequest}
          />
        }
        {
          this.state.answerDialogIsOpen && !loadingRows && !exportingGrid &&
          <ReplyDialog
            open={this.state.answerDialogIsOpen}
            handleCloseDialog={this.handleCloseAnswerDialog}
            handleAccept={this.handleAnswerRequest}
            title={getLocalizedString("answerChangeTitle")}
          />
        }
        {
          //abm
          this.state.typeToOpen !== "" && !loadingRows && !exportingGrid && !this.state.isReadOnly &&
          <RequestDialog
            fireCalculationData={fireCalculationData}
            raiseCalculationData={raiseCalculationData}
            colaborators={colaborators}
            open={this.state.typeToOpen !== ""}
            handleClose={this.handleCloseRequest}
            handleRequest={this.handleCreateRequest}
            companyColor={color}
            requestType={this.state.typeToOpen}
            recipients={recipients}
            handleNoRecipient={this.handleNoRecipient}
          />
        }
        {
          //read only
          this.state.typeToOpen !== "" && !loadingRows && !exportingGrid && this.state.isReadOnly &&
          <RequestDialog
            fireCalculationData={fireCalculationData}
            raiseCalculationData={raiseCalculationData}
            colaborators={colaborators}
            open={this.state.typeToOpen !== ""}
            handleClose={this.handleCloseRequest}
            handleRequest={this.handleCreateRequest}
            companyColor={color}
            requestType={this.state.typeToOpen}
            isReadOnly={this.state.isReadOnly}
            handleCloseViewDialog={this.handleCloseViewDialog}
            editData={this.state.currentRequestWorkflow[0]}
            recipients={recipients}
            handleNoRecipient={this.handleNoRecipient}
          />
        }
      </CommonPage>
    );
  }

  handleOpenViewDialog = (data) => {
    this.setState({ isReadOnly: true, typeToOpen: data[0].type });
  }

  handleCloseViewDialog = () => {
    this.setState({ isReadOnly: false, typeToOpen: "" });
  }

  handleAnswerRequest = (comment, status) => {
    let data;

    //Si acepta la propuesta, pasa al estado 4
    if (status) {
      data = {
        "code": this.state.currentRequestWorkflow[0].code,
        "id_status": "4",
        "comments": comment,
      }
    } else {
      //Si la rechaza, va al estado 5
      data = {
        "code": this.state.currentRequestWorkflow[0].code,
        "id_status": "5",
        "comments": comment,
      }
    }

    this.props.dispatch(changeRequestStatus(data, this.state.currentRequestWorkflow[0].type, false, false)).then((resp) => {
      this.handleCloseAnswerDialog(resp)
    }).then(resp => this.handleCloseSecondGridDialog());
  }

  handleOpenAnswerDialog = () => {
    this.setState({ answerDialogIsOpen: true });
  }

  handleCloseAnswerDialog = (shouldReloadTable) => {
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      this.setState({ answerDialogIsOpen: false },
        () => {
          this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
        }
      );
    } else {
      this.setState({ answerDialogIsOpen: false });
    }
  }

  handleOpenCancelDialog = () => {
    this.setState({ cancelDialogIsOpen: true });
  }

  handleCloseCancelDialog = () => {
    this.setState({ cancelDialogIsOpen: false });
    this.handleCloseSecondGridDialog();
  }

  handleCancelRequest = (request) => {
    this.props.dispatch(changeRequestStatus(request, this.state.currentRequestWorkflow[0].type, true, true));
  }

  handleCreateRequest = (request) => {
    this.props.dispatch(setColaboratorAppeal(request, this.state.includesHistory, this.state.typeToOpen));
  }

  handleSwitchClick = () => {
    this.setState({ includesHistory: !this.state.includesHistory },
      () => {
        this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
      }
    );
  };

  handleRowClick = row => {
    if (row && row.data !== undefined) {
      this.setState({ currentRequestWorkflow: row.data.complete_workflow, secondGridDialogIsOpen: true });
    }
  };

  handleCloseSecondGridDialog = () => {
    this.setState({ secondGridDialogIsOpen: false, typeToOpen: "" });
  }
}

export default connect(
  createStructuredSelector({
    recipients: getRecipients,

    usedDates: getUsedDates,
    myRequestsRows: getMyRequests,
    dateFormat: getDateFormat,
    locale: getLocale,

    fireCalculationData: getFireCalculationData,
    raiseCalculationData: getRaiseCalculationData,
    colaborators: getColaborators,

    decimalSeparator: getDecimalSeparator,

    exportingGrid: getIsExportingGrid,
    loadingRows: loading,
    color: getAppColor,

    userData: getUserData,
    loginResponse: getLoginResponse,
    theme: getTheme,
  })
)(MyRequests);
