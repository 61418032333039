import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberComparatorMax3,
	numberFormatterMin2,
	numberFormatterMin0Max3,
	numberFilterParams,
	numberFilterParamsWithThreeDigits,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "employee_code",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("nameAndSurname"),
            field: "employee_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("sentTo"),
            field: "manager",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        },
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("amountOfDays"),
            field: "duration_days",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("startDate"),
            field: "start_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "end_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("timePerDay"),
            field: "time_per_day",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("value"),
            field: "value",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myRequests],
        config: {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("lastActionDate"),
            field: "save_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("lastActionTime"),
            field: "save_time",
            filter: "agTextColumnFilter",
        }
    }, {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("responsible"),
            field: "manager",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsSecondGrid],
        config: {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['myRequestsViewBalance'],
        config: {
            headerName: 'Fecha de movimiento',
            field: 'Fecha de movimiento',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: ['myRequestsViewBalance'],
        config: {
            headerName: 'Descripción',
            field: 'Descripción',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['myRequestsViewBalance'],
        config: {
            headerName: 'Crédito',
            field: 'Crédito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['myRequestsViewBalance'],
        config: {
            headerName: 'Débito',
            field: 'Débito',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['myRequestsViewBalance'],
        config: {
            headerName: 'Saldo al movimiento',
            field: 'Saldo al movimiento',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax3,
            filterParams: numberFilterParamsWithThreeDigits,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max3,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]