import NAME from "./constants";

export const getHelp = store => store[NAME].helpDocumentation;

export const loading = store =>
  store[NAME].exporting || store[NAME].gettingMyRequests || store[NAME].settingRequest;

export const getRecipients = store => store[NAME].recipients;
export const getMyRequests = store => store[NAME].myRequests;
export const getRaiseCalculationData = store => store[NAME].raiseCalculationData;
export const getFireCalculationData = store => store[NAME].fireCalculationData;
export const getColaborators = store => store[NAME].colaborators;
export const getDecimalSeparator = store => store[NAME].decimalSeparator;
export const getLocale = store => store[NAME].currencyLocalization;
export const getDateFormat = store => store[NAME].dateFormat;
export const getUsedDates = store => store[NAME].usedDates;
