import React from "react";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid
} from "@material-ui/core";
import { TextField, Select, DatePicker } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateIsAfterToday, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import moment from "moment-business-days";
import createDecorator from 'final-form-calculate'
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import MyVacationsDialog from "@icarius-common/myVacationsDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { requestsIds } from "@icarius-utils/requestsIds";
import { getUserData } from "src/app/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

class NewVacationContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myVacationsDialogIsOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.isReadOnly && !this.props.isForEdit && this.props.recipients.vacation.length === 0) {
      this.props.handleNoRecipient();
      this.handleClose();
    }
  }

  handleClose = (shouldReloadTable) => {
    if (this.props.isForEdit && shouldReloadTable) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  handleCloseMyVacationsDialog = () => this.setState({ myVacationsDialogIsOpen: false });
  handleOpenMyVacationsDialog = () => this.setState({ myVacationsDialogIsOpen: true });

  calculateNextDay = () => {
    // A partir del siguiente dia
    let i = 1;
    let fechaBase = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + i);

    return fechaBase;
  }

  textIsValid = values => {
    if (this.props.isForEdit)
      return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 1024;
    else
      return values.descripcion === undefined || (values.descripcion !== undefined && values.descripcion.length >= 0 && values.descripcion.length <= 1024);
  };

  dataIsComplete = values => {
    if (!this.props.isForEdit && !this.props.isReadOnly && !values.receptor) return false;
    if (values.type && values.amountOfDays && values.timePerDay && this.textIsValid(values) && dateIsAfterToday(values.date))
      return true;
    return false;
  };

  validateAndCreate = values => {
    if (this.textIsValid(values) && this.dataIsComplete(values)) {

      if (!this.props.startHolidaysOnNonWorkingDay && !moment(values.date).isBusinessDay()) {
        this.props.dispatch(openSnackbarAction({ duration: null, msg: "La fecha de inicio o fecha desde, no puede ser un día Sábado, Domingo o Feriado", severity: "error" }));
        return;
      }

      if (this.props.validateDateRange(values.date, values.dateEnd)) {
        const request = {
          code: this.props.requestCode,
          id_type: "VAC",
          id_status: "3",
          id_subtype: values.type,
          id_time_per_day: values.timePerDay.toString(),
          duration_days: values.amountOfDays,
          start_date: formatDate(values.date),
          end_date: formatDate(values.dateEnd),
          comments: values.descripcion,
          receptor: values.receptor,
        }

        if (!this.props.isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }

        this.handleRequest(request);
      } else {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("datesCollide"), severity: "error" }));
      }
    } else {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  handleRequest = request => {
    this.props.handleRequest(request);
    if (!this.props.isForEdit) {
      this.handleClose(true);
    }
  };

  createVacationDaysArray = (typeVacation, maxAmount) => {
    let auxArray = [];
    let cont = 1;
    let totalDays;

    if (maxAmount) {
      do {
        auxArray.push({ FldValue: cont, Descr: cont });
        cont++;
      } while (cont <= maxAmount)
    } else {
      switch (typeVacation) {
        case "UV":
          totalDays = this.props.vacationCalculationData.available_legal_vacation_days;
          if (this.props.vacationCalculationData.additional_legal_days) {
            totalDays += this.props.vacationCalculationData.additional_legal_days;
          }

          break;
        case "VA": totalDays = this.props.vacationCalculationData.available_extra_vacation_days;
          break;
        default: break;
      }
      for (cont; cont <= totalDays; cont++) {
        auxArray.push({ FldValue: cont, Descr: cont });
      }
    }
    return auxArray;
  }

  calculateEndVacationDate = (startDate, amountOfDays) => {
    if (startDate && amountOfDays) {

      //tengo que mandar el vacationCalculationData
      if (this.props.vacationCalculationData.does_workweek_include_saturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }

      if (!this.props.vacationCalculationData.do_vacations_include_holidays_and_weekends) {
        moment.updateLocale('es', {
          holidays: this.props.vacationCalculationData.holiday_calendar,
          holidayFormat: 'DD/MM/YYYY'
        });

        let initialDay = moment(startDate, "DD/MM/YYYY");
        // Tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
        if (!initialDay.isBusinessDay()) {
          initialDay = initialDay.nextBusinessDay();
        }

        return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
      } else {
        return moment(startDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();
      }
    }
  }

  render() {
    const { vacationCalculationData, editData, isForEdit, isReadOnly, recipients, hideViewAccountability, allowHalfDayHolidays } = this.props;

    let initialValues;
    let vacacionDaysArray = [];

    const recipientInitialValue = recipients.vacation.length === 1 ? recipients.vacation[0].code : "";

    if (!isForEdit && !isReadOnly) {
      initialValues = {
        date: "",
        amountOfDays: "",
        descripcion: "",
        timePerDay: "",
        type: "",
        receptor: recipientInitialValue, //si tiene un valor solo, inicializar con ese
      };
    } else {
      if (isForEdit && !isReadOnly) {
        initialValues = {
          date: createDateFromDDMMYYYY(editData.start_date),
          descripcion: editData.comments,
          type: editData.id_subtype,
          timePerDay: parseFloat(editData.id_time_per_day),
          amountOfDays: "",
        };
      }
      if (isReadOnly) {
        initialValues = {
          date: createDateFromDDMMYYYY(editData.start_date),
          descripcion: editData.comments,
          type: editData.id_subtype,
          timePerDay: parseFloat(editData.id_time_per_day),
          amountOfDays: editData.duration_days,
          dateEnd: createDateFromDDMMYYYY(editData.end_date),
        };
      }
    }

    if (!allowHalfDayHolidays && !isForEdit && !isReadOnly) {
      initialValues = {
        ...initialValues,
        timePerDay: 1
      };
    }

    if (isForEdit || vacationCalculationData.can_user_appeal || isReadOnly) {

      const timesPerDayArray = [
        { FldValue: "0.5", Descr: requestsIds.id_time_per_day["0.5"] },
        { FldValue: "1", Descr: requestsIds.id_time_per_day["1.0"] },
      ];

      const vacationsTypeArray = [
        { FldValue: "UV", Descr: requestsIds.id_subtype.UV },
        { FldValue: "VA", Descr: requestsIds.id_subtype.VA },
      ];

      const calculator = createDecorator(
        {
          field: 'amountOfDays',
          updates: {
            dateEnd: (amountOfDaysValue, allValues) => {
              if (isReadOnly) return allValues.dateEnd;
              return this.calculateEndVacationDate(allValues.date, amountOfDaysValue)
            }
          }
        },
        {
          field: 'date',
          updates: {
            dateEnd: (dateValue, allValues) => {
              if (isReadOnly) return allValues.dateEnd;
              return this.calculateEndVacationDate(dateValue, allValues.amountOfDays)
            }
          }
        },
        {
          field: 'type',
          updates: {
            amountOfDays: (typeValue, allValues) => {
              if (!isForEdit && !isReadOnly) { //el campo no va a cambiar si es editar
                vacacionDaysArray = [...this.createVacationDaysArray(typeValue)]
                return ""
              }
            }
          }
        },
        {
          field: 'type',
          updates: {
            dateEnd: () => { return null }
          }
        },
        {
          field: 'timePerDay',
          updates: {
            amountOfDays: (timesPerDayValue, allValues) => {
              if (!isForEdit && !isReadOnly) {
                vacacionDaysArray = [...this.createVacationDaysArray(allValues.type)]
                return allValues.amountOfDays;
              }
              else {
                vacacionDaysArray = [...this.createVacationDaysArray(initialValues.type, editData.duration_days)]
                return allValues.amountOfDays === "" ? editData.duration_days : allValues.amountOfDays;
              }
            }
          }
        },
        {
          field: 'timePerDay',
          updates: {
            dateEnd: (timesPerDayValue, allValues) => {
              if (!isForEdit && !isReadOnly) {
                return allValues.dateEnd;
              }
              else {
                return allValues.dateEnd ? allValues.dateEnd : createDateFromDDMMYYYY(editData.end_date)
              }
            }
          }
        },
      )

      return (
        <>
          {
            this.state.myVacationsDialogIsOpen &&
            <MyVacationsDialog
              open={this.state.myVacationsDialogIsOpen}
              handleClose={this.handleCloseMyVacationsDialog}
            />
          }

          <Form
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            decorators={[calculator]}
            initialValues={initialValues}
            onSubmit={this.validateAndCreate}
            render={({ handleSubmit, form, submitting, pristine, values, active }) => {
              window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container direction="column" justify="center">
                      <Grid container direction="row"
                        alignItems="center"
                        justify="space-between">
                        {
                          !isForEdit && !isReadOnly && !hideViewAccountability &&
                          <Typography
                            className={"whiteText"}
                            variant="subtitle1"
                            style={{ cursor: "pointer", textDecoration: "underline", zIndex: 100 }}
                            onClick={this.handleOpenMyVacationsDialog}
                            gutterBottom>
                            {getLocalizedString("viewVacationAccountability")}
                          </Typography>
                        }
                      </Grid>

                      {
                        !isForEdit && !isReadOnly &&
                        <Grid container direction="column" justify="center" item>
                          <Select
                            disabled={isForEdit || isReadOnly}
                            label={getLocalizedString("requestReceptor")}
                            name={"receptor"}
                            required
                            formControlProps={{
                              margin: "normal",
                            }}>
                            {
                              recipients.vacation.map((subItem) => (
                                <MenuItem
                                  className={"whiteText"}
                                  key={subItem.code}
                                  value={subItem.code}>
                                  {subItem.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      }

                      <Grid container direction="row" justify="center" item>
                        <Select
                          required={!isReadOnly}
                          disabled={isForEdit || isReadOnly}
                          label={getLocalizedString("vacationsType")}
                          name={"type"}
                          formControlProps={{
                            margin: "normal",
                          }}>
                          {
                            vacationsTypeArray.map((subItem) => (
                              <MenuItem
                                className={"whiteText"}
                                key={subItem.FldValue}
                                value={subItem.FldValue}>
                                {subItem.Descr}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>

                      <Grid container direction="row" item>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <DatePicker
                              required={!isReadOnly}
                              label={getLocalizedString("startDate")}
                              variant="inline"
                              disabled={isReadOnly}
                              disablePast={!isForEdit}
                              minDate={!isReadOnly && this.calculateNextDay()}
                              format="dd/MM/yyyy"
                              margin="normal"
                              name="date"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12} sm={6}
                          className="secondFieldPadding">
                          <Select
                            required={!isReadOnly}
                            disabled={isReadOnly || !this.props.allowHalfDayHolidays}
                            label={getLocalizedString("timePerDay")}
                            name={"timePerDay"}
                            formControlProps={{
                              margin: "normal",
                            }}>
                            {timesPerDayArray.map((subItem, index) => (
                              <MenuItem
                                className={"whiteText"}
                                key={subItem.FldValue}
                                value={subItem.FldValue}>
                                {subItem.Descr}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        {
                          !isReadOnly &&
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                className={
                                  !dateIsAfterToday(values.date) ? "errorColor" : "whiteText"
                                }
                                variant="caption"
                                gutterBottom>
                                {getLocalizedString("startDateValidation")}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      </Grid>

                      <Grid container direction="row" item>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          xs={12} sm={6}>
                          <Select
                            required={!isReadOnly}
                            disabled={isReadOnly}
                            label={getLocalizedString("amountOfDays")}
                            name={"amountOfDays"}
                            formControlProps={{
                              margin: "normal",
                            }}>
                            {
                              vacacionDaysArray.map((subItem, index) => (
                                <MenuItem
                                  className={"whiteText"}
                                  key={subItem.FldValue}
                                  value={subItem.FldValue}>
                                  {subItem.Descr}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          item
                          className="secondFieldPadding"
                          xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <DatePicker
                              label={getLocalizedString("endDate")}
                              disabled
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              name="dateEnd"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>


                      {(!this.props.isForEdit && !this.props.isReadOnly) && values.type === "UV" &&
                        <Grid container item style={{ paddingBottom: 15 }}>
                          <Typography
                            style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                            variant="caption"
                            gutterBottom>
                            {`Saldo disponible en la cuenta corriente: ${this.props.vacationCalculationData.available_legal_vacation_days}`}
                          </Typography>
                        </Grid>
                      }

                      {((this.props.isForEdit && !this.props.isReadOnly) || (this.props.isReadOnly)) &&
                        values.type === "UV" &&
                        values.amountOfDays > this.props.vacationCalculationData.available_legal_vacation_days &&
                        <Grid container item style={{ paddingBottom: 15 }}>
                          <Typography
                            style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                            variant="caption"
                            gutterBottom>
                            {
                              values.amountOfDays === 1 ?
                                `El día solicitado supera el saldo disponible en la cuenta corriente en ${values.amountOfDays - this.props.vacationCalculationData.available_legal_vacation_days} día${(values.amountOfDays - this.props.vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}` :
                                `Los ${values.amountOfDays} días solicitados superan el saldo disponible en la cuenta corriente en ${values.amountOfDays - this.props.vacationCalculationData.available_legal_vacation_days} día${(values.amountOfDays - this.props.vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}`}
                          </Typography>
                        </Grid>
                      }

                      {
                        isForEdit && !isReadOnly &&
                        <Grid container item style={{ paddingBottom: 15 }}>
                          <Typography
                            style={{ paddingTop: 5 }}
                            className={"whiteText"}
                            variant="caption"
                            gutterBottom>
                            {getLocalizedString("howToSelectMoreDays")}
                          </Typography>
                        </Grid>
                      }

                      <Grid
                        style={{ paddingTop: 16 }}
                        container
                        direction="row"
                        justify="flex-start"
                        item>
                        <Grid container direction="row" item xs={12}>
                          <TextField
                            label={getLocalizedString("detail")}
                            required={isForEdit && !isReadOnly}
                            id="fieldToFocus"
                            name={"descripcion"}
                            margin={"none"}
                            onInput={e => {
                              e.target.value = e.target.value.toString().slice(0, 1024);
                            }}
                            multiline={true}
                            rows={1}
                            rowsMax={3}
                            disabled={isReadOnly}
                          />
                          {
                            !isReadOnly &&
                            <Typography
                              style={{ paddingTop: 5 }}
                              className={!this.textIsValid(values) ? "errorColor" : "whiteText"}
                              variant="caption"
                              gutterBottom>
                              {!isForEdit && `${getLocalizedString("optionalField")}. `}
                              {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                                }. ${getLocalizedString("maxCharRequests")}.`}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  {
                    isReadOnly &&
                    <div style={{ marginTop: 32 }} />
                  }
                  {
                    !isReadOnly &&
                    <DialogActions>
                      <ButtonDialogAction
                        onClick={this.handleClose}
                        text={getLocalizedString("disagree")}
                      />
                      <ButtonDialogAction
                        type="submit"
                        isAccept
                        text={getLocalizedString("agree")}
                      />
                    </DialogActions>
                  }
                </form>
              )
            }}
          />
        </>
      );
    } else {
      return <CantCreateRequestMessage />
    }
  }
}
export default connect(
  createStructuredSelector({
    userData: getUserData,
  })
)(NewVacationContent);
