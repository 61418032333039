import NAME from "./constants";

export const getIsLoading = store =>
    store[NAME].isLoading ||
    store[NAME].modifyingMyPersonalData ||
    store[NAME].modifyingPersonalPhoto ||
    store[NAME].isLoadingLocation;
    
export const getMyPersonalData = store => store[NAME].myPersonalData;
export const getAvatar = store => store[NAME].avatar;
export const getEditableFields = store => store[NAME].editableFields;
export const getEnableCertificates = store => store[NAME].enableCertificates;
export const getEnableReceipts = store => store[NAME].enableReceipts;

export const getSectionsToHide = store => store[NAME].sectionsToHide;
export const getFieldsToHide = store => store[NAME].fieldsToHide;

export const getCounties = store => store[NAME].counties;
export const getProvinces = store => store[NAME].provinces;
export const getRegions = store => store[NAME].regions;