import { useEffect, useState } from "react";
import { getVoiceGenreAction } from "@icarius-common/audioPlayer/actions";
import { getQueriesGivenLevelAction } from "@icarius-pages/myPeople/actions";
import { getQueries, getIsLoadingQueries, getKpiData } from "@icarius-pages/myPeople/selectors";
import paths from "@icarius-localization/paths";
import { useSelector, useDispatch } from "react-redux";
import {
  getIsLoading,
  getEditableFields,
  getMyPersonalData,
  getEnableCertificates,
  getEnableReceipts,
} from "../selectors";
import {
  getUserData
} from "src/app/selectors";
import {
  getMyPersonalDataAction,
  getUserLocationDataAction,
  updateMyPersonalDataAction,
} from "../actions";
import { RESET_DATA } from "../actionTypes";

const useMyPersonalData = () => {

  const [changeMyPersonalPhotoIsOpen, setChangeMyPersonalPhotoIsOpen] = useState(false);
  const [myCertificatesDialogIsOpen, setMyCertificatesDialogIsOpen] = useState(false);
  const [myReceiptsDialogIsOpen, setMyReceiptsDialogIsOpen] = useState(false);
  const [queriesDialogIsOpen, setQueriesDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const editableFields = useSelector(getEditableFields);
  const employee = useSelector(getMyPersonalData);
  const userData = useSelector(getUserData);
  const enableCertificates = useSelector(getEnableCertificates);
  const enableReceipts = useSelector(getEnableReceipts);
  const queries = useSelector(getQueries);
  const kpiData = useSelector(getKpiData);
  const isLoadingQueries = useSelector(getIsLoadingQueries);
  const isPhotoModificable = editableFields.some((item) => item.name === "photo");

  useEffect(() => {
    dispatch(getUserLocationDataAction(userData.code))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getMyPersonalDataAction());
        }
      });
    dispatch(getQueriesGivenLevelAction(userData.code, paths.myPersonalData.replace('/', '')));

    return () => {
      dispatch({ type: RESET_DATA });
    }
  }, [dispatch, userData])

  const handleOpenMyCertificatesDialog = () => setMyCertificatesDialogIsOpen(true);
  const handleCloseMyCertificatesDialog = () => setMyCertificatesDialogIsOpen(false);

  const handleOpenMyReceiptsDialog = () => setMyReceiptsDialogIsOpen(true);
  const handleCloseMyReceiptsDialog = () => setMyReceiptsDialogIsOpen(false);

  const handleOpenChangeMyPersonalPhotoDialog = () => setChangeMyPersonalPhotoIsOpen(true);
  const handleCloseChangeMyPersonalPhotoDialog = () => setChangeMyPersonalPhotoIsOpen(false);

  const handleOpenQueriesDialog = () => setQueriesDialogIsOpen(true);
  const handleCloseQueriesDialog = () => setQueriesDialogIsOpen(false);

  const handleUpdatePersonalData = (data) => {
    dispatch(updateMyPersonalDataAction(data))
      .then(() => dispatch(getVoiceGenreAction()));
  }

  const state = {
    changeMyPersonalPhotoIsOpen,
    myCertificatesDialogIsOpen,
    myReceiptsDialogIsOpen,
    queriesDialogIsOpen,
  }

  const handlers = {
    handleOpenMyCertificatesDialog,
    handleCloseMyCertificatesDialog,
    handleOpenMyReceiptsDialog,
    handleCloseMyReceiptsDialog,
    handleOpenChangeMyPersonalPhotoDialog,
    handleCloseChangeMyPersonalPhotoDialog,
    handleOpenQueriesDialog,
    handleCloseQueriesDialog,
    handleUpdatePersonalData,
  }

  return {
    isLoading,
    employee,
    userData,
    enableCertificates,
    enableReceipts,
    queries,
    kpiData,
    isLoadingQueries,
    isPhotoModificable,
    state,
    handlers,
  }
}

export default useMyPersonalData;