import { fieldTypes, TABS } from "@icarius-utils/constants";
import { getLocalizedString } from "@icarius-localization/strings";

const NAME = "PERSONAL_DATA_PAGE";

export const myPersonalDataFields = [
    {
        name: "NRO INTERNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("internalNumber"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "EMAIL",
        readOnlyType: fieldTypes.email,
        label: getLocalizedString("email"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "EMAIL PERSONAL",
        readOnlyType: fieldTypes.email,
        label: "Email personal",
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "TIPO DE FUNCIONARIO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("fType"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "CARGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("position"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "FECHA ASIGNACION CARGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("positionDate"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "FUNCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("function"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "FECHA ASIGNACION FUNCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("functionDate"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "ESTADO CIVIL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("civilState"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "SEXO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("sex"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "NACIONALIDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("nationality"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "CATEGORIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("category"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "ESPECIALIDAD",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("specialty"),
        tab: TABS.contract,
        column: 0,
    },
    {
        name: "FECHA DE NACIMIENTO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bornDate"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "CELULAR",
        readOnlyType: fieldTypes.phone,
        label: getLocalizedString("cellphone"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "LINKEDIN",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("linkedin"),
        tab: TABS.personal,
        column: 0,
    },
    {
        name: "DIRECCION",
        readOnlyType: fieldTypes.address,
        label: getLocalizedString("address"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "CONTRATISTA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contratista"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "PAIS",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("country"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "REGION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("region"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "PROVINCIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("provicia"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "COMUNA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("comuna"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "CODIGO POSTAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("postalCode"),
        tab: TABS.personal,
        column: 1,
    },
    {
        name: "TIPO DE CONTRATO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("contractType"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "FECHA DE INGRESO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("incomeDate"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "FECHA DE EGRESO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("outcomeDate"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "LUGAR DE TRABAJO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("jobLocation"),
        tab: TABS.location,
        column: 0,
    },
    {
        name: "GERENCIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("management"),
        tab: TABS.location,
        column: 0,
    },
    {
        name: "DEPARTAMENTO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("department"),
        tab: TABS.location,
        column: 0,
    },
    {
        name: "SECCION",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("section"),
        tab: TABS.location,
        column: 0,
    },
    {
        name: "FORMA DE PAGO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("paymentMethod"),
        tab: TABS.remuneration,
        column: 0,
    },
    {
        name: "BANCO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bank"),
        tab: TABS.remuneration,
        column: 0,
    },
    {
        name: "CUENTA BANCARIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bankAccount"),
        tab: TABS.remuneration,
        column: 0,
    },
    {
        name: "TIPO DE CUENTA BANCARIA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("bankAccountType"),
        tab: TABS.remuneration,
        column: 0,
    },
    {
        name: "UNIDAD SALARIAL",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("salaryUnit"),
        tab: TABS.remuneration,
        column: 1,
    },
    {
        name: "MONEDA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("coin"),
        tab: TABS.remuneration,
        column: 1,
    },
    {
        name: "SUELDO BASICO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("salary"),
        tab: TABS.remuneration,
        column: 1,
    },
    {
        name: "TIPO DE JORNADA",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("jornadaType"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "HORAS DIARIAS CONTRATO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("hoursPerDay"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "HORAS SEMANALES CONTRATO",
        readOnlyType: fieldTypes.number,
        label: 'Horas semanales contrato',
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "HORAS MENSUALES CONTRATO",
        readOnlyType: fieldTypes.number,
        label: getLocalizedString("hoursPerMonth"),
        tab: TABS.contract,
        column: 1,
    },
    {
        name: "SINDICATO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("laborUnion"),
        tab: TABS.remuneration,
        column: 1,
    },
    {
        name: "JUBILADO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("retired"),
        tab: TABS.remuneration,
        column: 1,
    },
    {
        name: "TURNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("shift"),
        tab: TABS.time,
        column: 0,
    },
    {
        name: "FECHA ASIGNACION TURNO",
        readOnlyType: fieldTypes.text,
        label: getLocalizedString("shiftDate"),
        tab: TABS.time,
        column: 0,
    },
    {
        name: "HORARIO",
        readOnlyType: fieldTypes.text,
        label: "Horario programado para hoy",
        tab: TABS.time,
        column: 0,
    },
];

export default NAME;
