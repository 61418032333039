import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  schedules: [],
  openCalendar: '',
  weeklyHours: '',
  minDates: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        schedules: action.payload.schedules,
        openCalendar: action.payload.openCalendar,
        minDates: action.payload.minDates,
        weeklyHours: action.payload.weeklyHours,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        schedules: action.payload.schedules,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
