import {
  GET_MY_REQUESTS,
  GET_MY_REQUESTS_FULFILLED,
  GET_MY_REQUESTS_REJECTED,
  SET_COLABORATOR_APPEAL,
  SET_COLABORATOR_APPEAL_FULFILLED,
  SET_COLABORATOR_APPEAL_REJECTED,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  MODIFY_REQUEST_STATUS,

} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getMyRequestsManagerAPI, setAppealRaise, setAppealFire, modifyFireAppealStatus, modifyRaiseAppealStatusAPI, } from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { requestsIds, getRequestSnackbarText } from "@icarius-utils/requestsIds";

export const getMyRequestsRows = (includesHistory) => async (dispatch) => {

  dispatch({ type: GET_MY_REQUESTS });
  try {
    let response = await getMyRequestsManagerAPI(`?include_historicals=${includesHistory}`);

    let recipients = response.data.recipients;
    let myRequests = response.data.result;
    let raiseCalculationData = response.data.raise_calculation_data;
    let fireCalculationData = response.data.fire_calculation_data;
    let colaborators = response.data.colaborators;
    let decimalSeparator = response.data.decimal_separator;
    let currencyLocalization = response.data.currency_localization;
    let dateFormat = response.data.date_format;

    dispatch({
      type: GET_MY_REQUESTS_FULFILLED,
      payload: {
        myRequests: myRequests,
        raiseCalculationData: raiseCalculationData,
        fireCalculationData: fireCalculationData,
        colaborators: colaborators,
        decimalSeparator: decimalSeparator,
        currencyLocalization: currencyLocalization,
        dateFormat: dateFormat,
        recipients: recipients,
      },
    });

    if (response.data.result === false) {
      dispatch({ type: GET_MY_REQUESTS_REJECTED });
      return;
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_MY_REQUESTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const setColaboratorAppeal = (request, includesHistory, type) => async (dispatch) => {
  dispatch({ type: SET_COLABORATOR_APPEAL });
  try {
    let response;

    switch (type) {
      case requestsIds.id_type.RS:
        response = await setAppealRaise(request);
        break;
      case requestsIds.id_type.FR:
        response = await setAppealFire(request);
        break;
      default: break;
    }

    let status = response.data && response.data.status;

    if (status === "OK") {
      let payloadData = {
        data: response.data.result[0],
        includesHistory: includesHistory,
        type: type,
      }

      dispatch({
        type: SET_COLABORATOR_APPEAL_FULFILLED,
        payload: payloadData,
      });

      dispatch(openSnackbarAction({ msg: getRequestSnackbarText("1"), severity: "success" }));

      return response;
    } else {
      let msg;
      switch (response.data.status) {
        case "VALIDATION_ERROR":
          msg = getLocalizedErrorString("validationErrorRequests");
          break;
        case "APPEAL_EXISTS":
          if (type === requestsIds.id_type.RS) {
            msg = getLocalizedErrorString("appealExistsRiseErrorRequest");
          } else {
            msg = getLocalizedErrorString("appealExistsFireErrorRequest");
          }
          break;
        case "ERROR_DAYS_QUANTITY":
          msg = getLocalizedErrorString("errorDaysQuantityRequests");
          break;
        case "ERROR_FORMAT_DATES":
          msg = getLocalizedErrorString("formatDateErrorRequests");
          break;
        case "ERROR_DATES":
          msg = getLocalizedErrorString("dateErrorRequests");
          break;
        case "ERROR_OVERLAP":
          msg = getLocalizedErrorString("overlapErrorRequests");
          break;
        default:
          msg = getLocalizedErrorString("defaultException");
      }
      dispatch({ type: SET_COLABORATOR_APPEAL_REJECTED });
      dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
      return;
    }
  } catch (e) {
    dispatch({ type: SET_COLABORATOR_APPEAL_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const changeRequestStatus = (data, type, canAppeal = false, shouldRemove = false) => async (dispatch) => {
  dispatch({ type: MODIFY_REQUEST_STATUS });
  try {

    let response;

    switch (type) {
      case requestsIds.id_type.FR:
        response = await modifyFireAppealStatus(data);
        break;
      case requestsIds.id_type.RS:
        response = await modifyRaiseAppealStatusAPI(data);
        break;
      default: break;
    }


    if (response.data.status === "OK") {
      const payloadData = {
        data: data,
        shouldRemoveFromArray: shouldRemove,
        canUserAppeal: canAppeal,
        type: type,
      }

      dispatch(openSnackbarAction({ msg: getRequestSnackbarText(data.id_status), severity: "success" }));

      dispatch({
        type: MODIFY_REQUEST_STATUS_FULFILLED,
        payload: payloadData,
      });

    } else {
      let msg;
      let duration = 5000;
      switch (response.data.status) {
        case "ERROR_NO_DATE_AVAILABLE":
          msg = response.data.message;
          duration = null;
          break;
        case "VALIDATION_ERROR":
          msg = getLocalizedErrorString("validationErrorRequests");
          break;
        case "ERROR_DAYS_QUANTITY":
          msg = getLocalizedErrorString("errorDaysQuantityRequests");
          break;
        case "ERROR_FORMAT_DATES":
          msg = getLocalizedErrorString("formatDateErrorRequests");
          break;
        case "ERROR_DATES":
          msg = getLocalizedErrorString("dateErrorRequests");
          break;
        case "ERROR_OVERLAP":
          msg = getLocalizedErrorString("overlapErrorRequests");
          break;
        default:
          msg = getLocalizedErrorString("defaultException");
      }
      dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: msg, severity: "error", duration }));
      return;
    }

    return response;
  } catch (e) {
    dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};