import * as actionTypes from "./actionTypes";
import {
  getMyScheduleAPI,
  createMyScheduleAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  let duration = 5000;
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_DATE":
      errorString = `Para solicitar cambio de horario, la fecha del cambio debe estar entre el ${e.response?.data.dateFrom} y el ${e.response?.data.dateTo}`;
      break;
    case "INVALID_MAX_WEEK_HOURS":
      errorString = "El cambio de horario no está permitido porque la suma de las horas de todos los horarios programados para la semana, supera la cantidad pactada contractualmente con el colaborador";
      duration = 15000;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: duration }));
}

export const getMyScheduleAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getMyScheduleAPI();
    let data = response?.data?.data;
    let schedules = response?.data?.schedules;
    let openCalendar = response?.data?.openCalendar;
    let minDates = response?.data?.minDates;
    let weeklyHours = response?.data?.weeklyHours;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        schedules,
        openCalendar,
        minDates,
        weeklyHours,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createMyScheduleAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createMyScheduleAPI(dataToSend);
    let data = response?.data?.data;
    let schedules = response?.data?.schedules;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data, schedules },
    });


    dispatch(openSnackbarAction({ msg: 'Horarios actualizados con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};