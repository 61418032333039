import React from "react";
import { Grid } from "@material-ui/core";
import StepContainer from "@icarius-pages/myPeople/components/userPage/userForm/steps/stepContainer";
import DynamicInput from "@icarius-common/dynamicInputs";

const Section = (props) => {
    const {
        tab,
        name,
        editableFields,
        formData,
        setFormValue,
        fieldsToUse,
        fieldsToHide,
    } = props;

    const sectionFields = fieldsToUse?.filter((item) => item.tab === tab);

    return (
        <StepContainer title={name}>
            {
                [0, 1].map((column) => {
                    const fieldsByColumn = sectionFields.filter((item) => item.column === column);

                    return (
                        <Grid item xs={12} md={6} key={column}>
                            {
                                fieldsByColumn.map((item) => {
                                    const fieldConfig = editableFields?.find((field) => field.name === item.name);

                                    if (fieldsToHide.includes(item.name)) return null;

                                    return (
                                        <Grid
                                            key={item.name}
                                            container item alignItems="center" xs={12}
                                            style={{ paddingBottom: 15 }}
                                        >
                                            <DynamicInput
                                                fieldConfig={fieldConfig}
                                                label={item.label}
                                                value={formData[item.name]}
                                                readOnlyType={item.readOnlyType}
                                                handleChange={setFormValue}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    )
                })
            }
        </StepContainer>
    )
}

export default Section;