import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getProvinceByRegionAction,
  getRegionByCountryAction,
  getCountiesByProvinceAction,
} from "@icarius-pages/myPersonalData/actions";
import { myPersonalDataFields } from "@icarius-pages/myPersonalData/constants";

const MAX_LENGTH_LONG = 250;
const MAX_LENGTH_MEDIUM = 100;
const MAX_LENGTH_SMALL = 60;

const useHandleForm = (data, modifyCallback, invalidDataCallback, editableFields) => {

  const dispatch = useDispatch();
  const OPTIONS_FIELDS = editableFields?.filter((item) => item.type === "options").map((item) => item.name);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (["VIS_CELULAR", "VIS_DIRECCION", "VIS_ESTADOCIVIL", "VIS_FECHANACIMIENTO"].includes(fieldName)) {
        return data?.[fieldName] || false;
      }

      // este esta dentro de los OPTIONS_FIELDS pero se maneja distinto
      if (fieldName === "ASISTENTE DE AYUDA") {
        return data?.[fieldName] || "H";
      }

      if (OPTIONS_FIELDS.includes(fieldName)) {
        if (!data?.[fieldName]) return "";

        const fieldConfig = editableFields?.find((item) => item.name === fieldName);
        return fieldConfig?.options?.find((item) => item.value === data?.[fieldName])?.id || "";
      }


      return data?.[fieldName] || "";
    }

    const fieldNames = [
      ...myPersonalDataFields.map((item) => item.name),
      "VIS_CELULAR",
      "VIS_DIRECCION",
      "VIS_ESTADOCIVIL",
      "VIS_FECHANACIMIENTO",
      "ASISTENTE DE AYUDA",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());

  const dataIsValid = () => {
    if (!formData["CELULAR"]) formData["CELULAR"] = "";

    if (formData["CELULAR"] && formData["CELULAR"]?.length > MAX_LENGTH_SMALL) {
      invalidDataCallback(`Celular debe tener menos de ${MAX_LENGTH_SMALL} caracteres`);
      return false;
    }

    if (!formData["DIRECCION"] || formData["DIRECCION"]?.length > MAX_LENGTH_LONG) {
      invalidDataCallback(`Dirección es obligatorio y debe tener menos de ${MAX_LENGTH_LONG} caracteres`);
      return false;
    }

    if (formData["CODIGO POSTAL"] && formData["CODIGO POSTAL"]?.length > MAX_LENGTH_SMALL) {
      invalidDataCallback(`Código postal debe tener menos de ${MAX_LENGTH_SMALL} caracteres`);
      return false;
    }

    if (formData["LINKEDIN"] && formData["LINKEDIN"]?.length > MAX_LENGTH_MEDIUM) {
      invalidDataCallback(`Linkedin debe tener menos de ${MAX_LENGTH_MEDIUM} caracteres`);
      return false;
    }

    return true;
  };


  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {};

      if (editableFields.length > 0) {
        editableFields.forEach((item) => dataToSend[item.name] = formData[item.name] || '');

        dataToSend["VIS_CELULAR"] = formData["VIS_CELULAR"];
        dataToSend["VIS_DIRECCION"] = formData["VIS_DIRECCION"];
        dataToSend["VIS_ESTADOCIVIL"] = formData["VIS_ESTADOCIVIL"];
        dataToSend["VIS_FECHANACIMIENTO"] = formData["VIS_FECHANACIMIENTO"];
        dataToSend["ASISTENTE DE AYUDA"] = formData["ASISTENTE DE AYUDA"];

        modifyCallback(dataToSend);
      }

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if ("PAIS" === fieldName) {
      if (value === "AR") dispatch(getProvinceByRegionAction(value));
      if (value !== "AR") dispatch(getRegionByCountryAction(value));

      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
        "REGION": "",
      })
      return;
    }

    if ("REGION" === fieldName) {
      if (value) dispatch(getProvinceByRegionAction(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
        "PROVINCIA": "",
      })
      return;
    }

    if ("PROVINCIA" === fieldName) {
      if (value) dispatch(getCountiesByProvinceAction(value));
      setFormData({
        ...formData,
        [fieldName]: value,
        "COMUNA": "",
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;