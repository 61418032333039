import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { SaveIcon } from "@icarius-icons/index";
import {
  getCounties,
  getRegions,
  getProvinces,
  getEditableFields,
  getSectionsToHide,
  getFieldsToHide,
} from "@icarius-pages/myPersonalData/selectors";
import useHandleForm from "./useHandleForm";
import ChangePinDialog from "@icarius-common/changePinDialog";
import { LockIcon, WomanIcon, ManIcon } from "@icarius-icons";
import DynamicInput from "@icarius-common/dynamicInputs";
import { myPersonalDataFields } from "@icarius-pages/myPersonalData/constants";
import { TABS } from "@icarius-utils/constants";
import { getAppColor, getGeographicalDivisions } from "src/app/selectors";
import DataContainer from "./dataContainer";
import Section from "./section";

const SECTIONS_LIST = [
  {
    tab: TABS.personal,
    name: "Personales",
  },
  {
    tab: TABS.contract,
    name: "Contrato",
  },
  {
    tab: TABS.remuneration,
    name: "Remuneración",
  },
  {
    tab: TABS.location,
    name: "Ubicación",
  },
  {
    tab: TABS.time,
    name: "Tiempo",
  },
];

const UserForm = (props) => {

  const {
    data,
    handleUpdateEmployee,
  } = props;

  const [changePinDialogIsOpen, setChangePinDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const geographicalDivisions = useSelector(getGeographicalDivisions);
  const sectionsToHide = useSelector(getSectionsToHide);
  const fieldsToHide = useSelector(getFieldsToHide);
  const editableFields = useSelector(getEditableFields);
  const counties = useSelector(getCounties);
  const provinces = useSelector(getProvinces);
  const regions = useSelector(getRegions);
  const color = useSelector(getAppColor);

  const myPersonalDataFieldsToUse = useMemo(() => {
    let auxMyPersonalDataFields = [...myPersonalDataFields];

    const firstLevelField = auxMyPersonalDataFields.find((item) => item.name === "REGION");
    firstLevelField.label = geographicalDivisions.firstLevel;

    const secondLevelField = auxMyPersonalDataFields.find((item) => item.name === "PROVINCIA");
    secondLevelField.label = geographicalDivisions.secondLevel;

    const thirdLevelField = auxMyPersonalDataFields.find((item) => item.name === "COMUNA");
    thirdLevelField.label = geographicalDivisions.thirdLevel;

    return auxMyPersonalDataFields;
  }, [geographicalDivisions])

  const editableFieldsToUse = useMemo(() => {
    if (editableFields) {

      // hacerlos todos editables
      const auxEditableFields = editableFields.map((item) => {
        return ({
          ...item,
          editable: true,
        })
      });

      // asociar las regiones, comunas y provincias (que se traen de fetchs dinamicos) con el editableFields
      auxEditableFields.find((item) => item.name === "COMUNA").options = counties;
      auxEditableFields.find((item) => item.name === "PROVINCIA").options = provinces;
      auxEditableFields.find((item) => item.name === "REGION").options = regions;

      return auxEditableFields;
    }

    return null;
  }, [editableFields, counties, provinces, regions])

  const modify = (dataToSend) => {
    handleUpdateEmployee(dataToSend);
  }

  const openValidationError = (message) => {
    dispatch(openSnackbarAction({ msg: message || getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, modify, openValidationError, editableFieldsToUse);

  const handleOpenChangePinDialog = () => setChangePinDialogIsOpen(true);
  const handleCloseChangePinDialog = () => setChangePinDialogIsOpen(false);

  const genreSelectedStyle = {
    backgroundColor: color,
    borderRadius: "10px",
    cursor: "pointer",
    margin: "10px",
    width: 300,
    height: 60,
  }

  const genreNotSelectedStyle = {
    backgroundColor: "var(--genreBackground)",
    borderRadius: "10px",
    cursor: "pointer",
    margin: "10px",
    width: 300,
    height: 60,
  }

  return (
    <>
      <Grid container item xs={12} direction="row">
        <Grid container style={{ marginTop: 50 }}>
          <Grid container item xs={12} spacing={2}>
            <DataContainer
              label={getLocalizedString("employeeCode")}
              value={data["CODIGO EMPLEADO"]}
            />
            <DataContainer
              label={getLocalizedString("state")}
              value={data["ESTADO"]}
            />
            <DataContainer
              label={getLocalizedString("plantPersonnel")}
              value={data["PERSONAL DE PLANTA"]}
            />
          </Grid>
          <Grid container item xs={12}>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <Typography className={"whiteText"} style={{ fontSize: 28 }}>
                {'PROTECCIÓN DE DATOS'}
              </Typography>
              <Typography className={"whiteText"}>
                {getLocalizedString("rightPannelSwitchIndication")}:
              </Typography>
            </div>
            <Grid container item xs={12}>
              <Grid container xs={4} item>
                <DynamicInput
                  fieldConfig={{ name: "VIS_CELULAR", type: "check", editable: true }}
                  label={getLocalizedString("cellphoneVisibleShort")}
                  value={formData["VIS_CELULAR"]}
                  handleChange={setFormValue}
                />
              </Grid>
              <Grid container xs={4} item>
                <DynamicInput
                  fieldConfig={{ name: "VIS_ESTADOCIVIL", type: "check", editable: true }}
                  label={getLocalizedString("civilStateVisibleShort")}
                  value={formData["VIS_ESTADOCIVIL"]}
                  handleChange={setFormValue}
                />
              </Grid>
              <Grid container xs={4} item>
                <DynamicInput
                  fieldConfig={{ name: "VIS_FECHANACIMIENTO", type: "check", editable: true }}
                  label={getLocalizedString("bornDateVisibleShort")}
                  value={formData["VIS_FECHANACIMIENTO"]}
                  handleChange={setFormValue}
                />
              </Grid>
            </Grid>
          </Grid>
          <div style={{ display: "flex", padding: 30, paddingLeft: 0, cursor: "pointer" }} onClick={handleOpenChangePinDialog}>
            <LockIcon htmlColor={color} className={"whiteText"} />
            <Typography className={"whiteText"} style={{ marginLeft: 10, fontWeight: 500, textDecoration: "underline" }}>
              {'Cambiar mi PIN'}
            </Typography>
          </div>
          <Grid container alignItems="center" xs={12} item>
            <Typography className={"whiteText"}>
              {"Asistente de ayuda:"}
            </Typography>
            <Grid container item xs={12} spacing={2} justify="flex-start" style={{ marginTop: 10 }}>
              <Tooltip title={"María"} placement="bottom">
                <div
                  style={formData["ASISTENTE DE AYUDA"] === "M" ? genreSelectedStyle : genreNotSelectedStyle}
                  onClick={() => setFormValue("M", "ASISTENTE DE AYUDA")}
                >
                  <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <WomanIcon style={{ height: 35, width: 25 }} isSelected={formData["ASISTENTE DE AYUDA"] !== "H"} />
                  </div>
                </div>
              </Tooltip>
              <Tooltip title={"Lorenzo"} placement="bottom">
                <div
                  style={formData["ASISTENTE DE AYUDA"] === "H" ? genreSelectedStyle : genreNotSelectedStyle}
                  onClick={() => setFormValue("H", "ASISTENTE DE AYUDA")}
                >
                  <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <ManIcon style={{ height: 35, width: 25 }} isSelected={formData["ASISTENTE DE AYUDA"] === "H"} />
                  </div>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: 30 }}>
          {
            SECTIONS_LIST.map((item) => {
              if (item.tab === TABS.contract && sectionsToHide.includes("contract")) return null;
              if (item.tab === TABS.remuneration && sectionsToHide.includes("remuneration")) return null;
              if (item.tab === TABS.time && sectionsToHide.includes("time")) return null;

              return (
                <Section
                  key={item.tab}
                  tab={item.tab}
                  name={item.name}
                  formData={formData}
                  setFormValue={setFormValue}
                  fieldsToUse={myPersonalDataFieldsToUse}
                  editableFields={editableFieldsToUse}
                  fieldsToHide={fieldsToHide}
                />
              )
            })
          }
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="flex-end">
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} startIcon={<SaveIcon />} />
      </Grid>
      {
        changePinDialogIsOpen &&
        <ChangePinDialog
          open={changePinDialogIsOpen}
          onClose={handleCloseChangePinDialog}
        />
      }
    </>
  );
}

export default UserForm;