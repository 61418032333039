import React from "react";
import { connect } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle } from "@material-ui/core";
import NewVacationContent from "./newVacationContent";
import NewPermissionContent from "./newPermissionContent";
import NewLoanContent from "./newLoanContent";
import { getLocalizedString } from "@icarius-localization/strings";
import { requestsIds } from "@icarius-utils/requestsIds";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

class RequestDialog extends React.Component {

  handleClose = (value) => {
    if (this.props.isReadOnly) {
      this.props.handleCloseViewDialog();
    }

    if (this.props.isForEdit && typeof value === "boolean" && value) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  validateDateRange = (start, end) => {
    const { usedDates } = this.props;
    const formatedStart = start.setHours(0, 0, 0, 0);
    const formatedEnd = end.setHours(0, 0, 0, 0);

    let resp = true;

    usedDates && usedDates.forEach(date => {
      let formatedDate = createDateFromDDMMYYYY(date);

      if (formatedDate >= formatedStart && formatedDate <= formatedEnd) {
        resp = false;
      }
    })
    return resp;
  }

  renderContent = () => {
    switch (this.props.requestType) {
      case requestsIds.id_type.VAC:
        return (
          <NewVacationContent
            startHolidaysOnNonWorkingDay={this.props.startHolidaysOnNonWorkingDay}
            allowHalfDayHolidays={this.props.allowHalfDayHolidays}
            isReadOnly={this.props.isReadOnly}
            validateDateRange={this.validateDateRange}
            vacationCalculationData={this.props.vacationCalculationData}
            companyColor={this.props.companyColor}
            handleRequest={this.props.handleRequest}
            employee={this.props.employee}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            dispatch={this.props.dispatch}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
            hideViewAccountability={this.props.hideViewAccountability}
          />
        );
      case requestsIds.id_type.PERM:
        return (
          <NewPermissionContent
            isReadOnly={this.props.isReadOnly}
            validateDateRange={this.validateDateRange}
            vacationCalculationData={this.props.vacationCalculationData}
            typesPermission={this.props.typesPermission}
            permissionCalculationData={this.props.permissionCalculationData}
            companyColor={this.props.companyColor}
            handleRequest={this.props.handleRequest}
            employee={this.props.employee}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            dispatch={this.props.dispatch}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
            handleViewBalance={this.props.handleViewBalance}
          />
        );
      case requestsIds.id_type.ANT:
        return (
          <NewLoanContent
            isReadOnly={this.props.isReadOnly}
            advanceCalculationData={this.props.advanceCalculationData}
            typesAdvances={this.props.typesAdvances}
            companyColor={this.props.companyColor}
            handleRequest={this.props.handleRequest}
            employee={this.props.employee}
            handleClose={this.handleClose}
            editData={this.props.editData}
            isForEdit={this.props.isForEdit}
            requestCode={this.props.requestCode}
            dispatch={this.props.dispatch}
            recipients={this.props.recipients}
            handleNoRecipient={this.props.handleNoRecipient}
          />
        );
      default:
        return "";
    }
  };

  shouldComponentUpdate = () => {
    return false;
  }

  render() {
    const { open } = this.props;

    const StyledDialog = withStyles({
      paper: {
        maxWidth: this.props.requestType === requestsIds.id_type.ANT ? "820px" : "90%",
        minWidth: this.props.requestType === requestsIds.id_type.ANT && window.innerWidth > 959 ? "820px" : "50%",
      },
    })(Dialog);

    return (
      <StyledDialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        scroll={"paper"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {(!this.props.isForEdit || this.props.isReadOnly) && `${getLocalizedString("requestCreateTitle")} ${this.props.requestType}`}
          {this.props.isForEdit && !this.props.isReadOnly && `${getLocalizedString("requestEditTitle")} ${this.props.requestType}`}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleClose} />
        {this.renderContent()}
      </StyledDialog>
    );
  }
}

export default connect()(RequestDialog);
