import React, { useMemo, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import useMySchedule from "./useMySchedule";
import {
  Eventcalendar,
  Popup,
  Select,
} from '@mobiscroll/react';
import {
  Checkbox,
  Typography,
  FormControl,
  Select as SelectMUI,
  MenuItem,
  InputLabel,
  Box,
  Tooltip,
} from "@material-ui/core";
import useGenericSchedule from "./useGenericSchedule";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { calendarTypeColors } from "@icarius-utils/colors";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const typesStrings = {
  holiday: 'Feriados',
  free: 'Libres',
  scheduled: 'Horarios programados',
  exception: 'Excepciones',
  absence: 'Inasistencias',
  request: 'Solicitudes',
}

const viewSettings = {
  calendar: {
    type: 'month',
  }
};

const responsivePopup = {
  medium: {
    display: 'center',
    width: 400,
    fullScreen: false,
    touchUi: false,
    showOverlay: false
  }
};

const MyPeopleSchedule = () => {

  const [showShortTitle, setShowShortTitle] = useState(true);
  const [typesSelected, setTypesSelected] = useState([])

  const {
    isLoading,
    data,
    schedules,
    openCalendar,
    minDates,
    weeklyHours,
    handleSave,
  } = useMySchedule();

  const {
    scheduleFunctions,
    scheduleData,
  } = useGenericSchedule(schedules, data, showShortTitle);

  const titleCheckItem = () => (
    <div
      style={{ width: 175, marginRight: 10, display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => setShowShortTitle(!Boolean(showShortTitle))}
    >
      <Checkbox checked={showShortTitle} />
      <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
        {'Horario abreviado'}
      </Typography>
    </div>
  )

  const typesSelectItem = () => (
    <FormControl style={{ width: '300px', marginRight: 10 }}>
      <InputLabel shrink id={`label`}>{'Filtrar tipos'}</InputLabel>
      <SelectMUI
        multiple
        value={typesSelected}
        labelId={`label`}
        id={`select`}
        onChange={(e) => setTypesSelected(e.target.value)}
        margin={"none"}
        renderValue={(values) => values.length ? values.map((item) => typesStrings[item]).join(', ') : 'Todos'}
        displayEmpty
      >
        {
          ['holiday', 'free', 'scheduled', 'exception', 'absence', 'request'].map(item => (
            <MenuItem
              className={"whiteText"}
              key={item}
              value={item}
            >
              <div style={{ marginRight: 10, height: 10, width: 10, borderRadius: '50%', background: calendarTypeColors[item] }} />
              {typesStrings[item]}
            </MenuItem>
          ))
        }
      </SelectMUI>
    </FormControl>
  )

  const saveButton = () => (
    <CustomIconButton
      title={'Guardar'}
      type={'save'}
      onClick={() => handleSave(scheduleData.shifts)}
    />
  )

  const openCalendarLabel = () => (
    <Tooltip title={`Calendario de tiempos vigente: ${openCalendar || '-'}`}>
      <Typography className="whiteText" noWrap>
        <Box component="span" style={{ textDecoration: 'underline' }}>{'Calendario de tiempos vigente:'}</Box>{` ${openCalendar || '-'}`}
      </Typography>
    </Tooltip>
  )

  const filteredShifts = useMemo(() => {
    if (!scheduleData.shifts) return [];
    if (!typesSelected.length) return scheduleData.shifts;

    return scheduleData.shifts
      .filter(item => {
        if (item?.type) {
          return typesSelected.includes(item.type);
        }
        return false;
      });
  }, [scheduleData.shifts, typesSelected])

  const schedulesFormatted = useMemo(() => {
    return schedules.map(item => ({ value: item.key, text: item.value }));
  }, [schedules])

  const dateToUse = useMemo(() => {
    if (!typesSelected.length || !minDates) return new Date();
    const minDatesSelected = typesSelected.map(item => minDates[item] ? createDateFromDDMMYYYY(minDates[item]) : new Date());
    return minDatesSelected.reduce((a, b) => a < b ? a : b);
  }, [typesSelected, minDates])

  return (
    <CommonPage
      title={'Mis horarios programados'}
      isLoading={isLoading}
      isNotGridPage
      menuItems={[titleCheckItem, typesSelectItem, saveButton]}
      customHeader={openCalendarLabel}
    >
      <div style={{ margin: 10, marginTop: 0 }}>
        <Typography className="whiteText" noWrap style={{ margin: 10, marginTop: 0 }}>
          {`Click en un horario para solicitar el cambio. ${weeklyHours ? `(Tus horas semanales por contrato: ${weeklyHours})` : ""}`}
        </Typography>
        <Eventcalendar
          view={viewSettings}
          data={filteredShifts}
          dragToCreate={false}
          dragToResize={false}
          dragToMove={false}
          clickToCreate={false}
          cssClass="md-employee-shifts"
          dayNamesShort={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
          renderLabelContent={scheduleFunctions.renderLabelContent}
          onEventClick={scheduleFunctions.onEventClick}
          defaultSelectedDate={dateToUse}
          key={dateToUse}
        />
        {
          scheduleData.popUpIsOpen &&
          <Popup
            isOpen={scheduleData.popUpIsOpen}
            headerText={scheduleData.headerText}
            onClose={scheduleFunctions.onClose}
            buttons={scheduleFunctions.getPopupButtons()}
            responsive={responsivePopup}
            fullScreen={true}
            contentPadding={false}
            display="bottom"
            cssClass="employee-shifts-popup"
          >
            <div className="mbsc-form-group">
              <Select
                data={schedulesFormatted}
                label="Seleccionar horario"
                onChange={(args) => scheduleFunctions.handleDateChange(args)}
                value={scheduleData.schedule}
              />
            </div>
          </Popup>
        }
      </div>
    </CommonPage>
  )
}

export default MyPeopleSchedule;