import React from "react";
import {
  Typography,
  DialogContent,
  DialogActions, MenuItem, Grid
} from "@material-ui/core";
import { TextField, Select, DatePicker, TimePicker } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateIsAfterToday, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import moment from "moment-business-days";
import createDecorator from 'final-form-calculate'
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { requestsIds } from "@icarius-utils/requestsIds";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
class NewPermissionContent extends React.Component {

  componentDidMount() {
    if (!this.props.isReadOnly && !this.props.isForEdit && this.props.recipients.permission.length === 0) {
      this.props.handleNoRecipient();
      this.handleClose();
    }
  }

  handleClose = (shouldReloadTable) => {
    if (this.props.isForEdit && shouldReloadTable) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  timePerDayIsValid = (values) => {
    const { isForEdit, editData } = this.props;
    const { timePerDay, type } = values;

    if (!timePerDay || !type) return false;

    const typeSelected = this.getPermissionTypeSelected(type);

    if (!typeSelected) return false;

    let maxHour = null;
    let maxMinutes = null;
    if (editData && editData.hasOwnProperty("cant_max")) {
      maxHour = Math.floor(editData.cant_max);
      maxMinutes = parseInt(this.decToMM(editData.cant_max));
    } else {
      maxHour = Math.floor(typeSelected.cant_max);
      maxMinutes = parseInt(this.decToMM(typeSelected.cant_max));
    }

    if (typeSelected.unidad !== "H") return true;

    if (isForEdit) {
      const originalDate = this.createDateFromHHMM(editData.id_time_per_day);

      //si la fecha nueva tiene hora mayor, error
      if (originalDate.getHours() < timePerDay.getHours()) return false;

      //si las fechas tienen misma hora, pero la nueva tiene minutos mayor, error
      if (originalDate.getHours() === timePerDay.getHours() && originalDate.getMinutes() < timePerDay.getMinutes()) return false;
    }

    if (timePerDay.getHours() === 0 && timePerDay.getMinutes() < 10) return false; //si es menos de 00:10
    if (timePerDay.getHours() > maxHour) return false; //si es mas de max hour
    if (timePerDay.getMinutes() > maxMinutes && timePerDay.getHours() === maxHour) return false; //si es mas de max minutes
    return true;
  }

  calculateNextDay = () => {
    // A partir del siguiente dia
    let i = 1;
    let fechaBase = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + i);

    return fechaBase;
  }

  textIsValid = values => {
    if (this.props.isForEdit)
      return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 1024;
    else
      return values.descripcion === undefined || (values.descripcion !== undefined && values.descripcion.length >= 0 && values.descripcion.length <= 1024);
  };

  dataIsComplete = values => {
    if (!this.props.isForEdit && !this.props.isReadOnly && !values.receptor) return false;
    if (values.type && values.amountOfDays && values.timePerDay && this.textIsValid(values) && dateIsAfterToday(values.date))
      return true;
    return false;
  };

  validateAndCreate = values => {
    if (this.textIsValid(values) && this.dataIsComplete(values) && this.timePerDayIsValid(values)) {
      if (this.props.validateDateRange(values.date, values.dateEnd)) {
        const request = {
          code: this.props.requestCode,
          id_type: "PERM",
          id_status: "3",
          id_subtype: values.type,
          id_time_per_day: this.getPermissionTypeSelected(values.type).unidad === "H" ? values.timePerDay.toTimeString().split(' ')[0].substring(0, 5) : values.timePerDay.toString(),
          duration_days: values.amountOfDays,
          start_date: formatDate(values.date),
          end_date: formatDate(values.dateEnd),
          comments: values.descripcion,
          receptor: values.receptor,
        }

        if (!this.props.isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }
        this.handleRequest(request);
      } else {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("datesCollide"), severity: "error" }));
      }
    } else {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  handleRequest = request => {
    this.props.handleRequest(request);
    if (!this.props.isForEdit) {
      this.handleClose(true);
    }
  };

  createPermissionDaysArray = (maxAmount) => {
    let auxArray = [];
    let cont = 1;

    do {
      auxArray.push({ FldValue: cont, Descr: cont });
      cont++;
    } while (cont <= maxAmount)

    return auxArray;
  }

  getPermissionTypeSelected = (id_type) => {
    return this.props.typesPermission.find(type => type.code_type === id_type);
  }

  calculateEndPermissionDate = (startDate, amountOfDays, id_type) => {
    if (startDate && amountOfDays) {

      if (this.props.permissionCalculationData.does_workweek_include_saturdays) {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5, 6]
        });
      } else {
        moment.updateLocale('es', {
          workingWeekdays: [1, 2, 3, 4, 5]
        });
      }
      
      const type = this.getPermissionTypeSelected(id_type);
      
      if (!type.include_holidays_and_weekends) {
        moment.updateLocale('es', {
          holidays: this.props.permissionCalculationData.holiday_calendar,
          holidayFormat: 'DD/MM/YYYY'
        });
        
        let initialDay = moment(startDate, "DD/MM/YYYY");
        // Tengo que avanzar hasta el proximo dia habil, en caso de que este no lo sea
        if (!initialDay.isBusinessDay()) {
          initialDay = initialDay.nextBusinessDay();
        }
        
        return initialDay.businessAdd(amountOfDays - 1, 'days').toDate();
      } else {
        return moment(startDate, "DD/MM/YYYY").add(amountOfDays - 1, 'days').toDate();
      }
    }
  }

  createTypesArray = () => {
    return this.props.typesPermission.map((type) => {
      return { FldValue: type.code_type, Descr: type.name, class: type.clase };
    })
  }

  createTimesPerDayArrayTypeHour = (cantMax) => {
    let timesPerDayArrayTypeHour = [
      { FldValue: "0.5", Descr: "Media Hora" }];

    for (let i = 1; i <= cantMax; i++) {
      timesPerDayArrayTypeHour.push({ FldValue: i, Descr: `${i} hrs` })
    }
    return timesPerDayArrayTypeHour;
  }

  createDateFromHHMM = (value) => {
    let newTime = new Date();
    newTime.setHours(value.substring(0, 2));
    newTime.setMinutes(value.substring(3, 5));
    newTime.setSeconds(0);
    return newTime;
  }

  decToMM = (decimalTimeString) => {
    let n = new Date(0, 0);
    n.setSeconds(decimalTimeString * 60 * 60);
    n.setMinutes(decimalTimeString * 60);
    return n.toTimeString().slice(3, 5);
  }

  getIsClassC = (arrayToFind, type) => {
    if (type) {
      return arrayToFind.find((item) => item.FldValue === type).class === "C"
    }
    return false;
  }

  render() {
    const { permissionCalculationData, isForEdit, editData, isReadOnly, recipients } = this.props;

    let initialValues;

    const recipientInitialValue = recipients.permission.length === 1 ? recipients.permission[0].code : "";

    if (!isForEdit && !isReadOnly) {
      initialValues = {
        date: "",
        amountOfDays: "",
        descripcion: "",
        timePerDay: "",
        type: "",
        receptor: recipientInitialValue, //si tiene un valor solo, inicializar con ese
      };
    } else {
      if (isForEdit && !isReadOnly) {
        initialValues = {
          date: createDateFromDDMMYYYY(editData.start_date),
          descripcion: editData.comments,
          type: editData.id_subtype,
          timePerDay: "",
          amountOfDays: "",
        };
      }
      if (isReadOnly) {
        initialValues = {
          date: createDateFromDDMMYYYY(editData.start_date),
          descripcion: editData.comments,
          type: editData.id_subtype,
          timePerDay: "",
          amountOfDays: editData.duration_days,
          dateEnd: createDateFromDDMMYYYY(editData.end_date),
        };
      }
    }

    let permissionDaysArray = [];

    if (isForEdit || permissionCalculationData.can_user_appeal || isReadOnly) {

      let timesPerDayArray = [];

      const timesPerDayArrayTypeDay = [
        { FldValue: "0.5", Descr: requestsIds.id_time_per_day["0.5"] },
        { FldValue: "1", Descr: requestsIds.id_time_per_day["1.0"] },
      ];

      const vacationsTypeArray = this.createTypesArray();

      const calculator = createDecorator(
        {
          field: 'amountOfDays',
          updates: {
            dateEnd: (amountOfDaysValue, allValues) => {
              if (isReadOnly) {
                return allValues.dateEnd;
              }
              else {
                return this.calculateEndPermissionDate(allValues.date, amountOfDaysValue, allValues.type)
              }
            }
          }
        },
        {
          field: 'date',
          updates: {
            dateEnd: (dateValue, allValues) => {
              if (isReadOnly) {
                return allValues.dateEnd;
              } else {
                return this.calculateEndPermissionDate(dateValue, allValues.amountOfDays, allValues.type)
              }
            }
          }
        },
        {
          field: 'type',
          updates: {
            timePerDay: (typeValue, allValues) => {
              const type = this.getPermissionTypeSelected(typeValue);

              if (type) {
                timesPerDayArray = type.unidad === "D" ? [...timesPerDayArrayTypeDay] : [...this.createTimesPerDayArrayTypeHour(isForEdit ? editData.id_time_per_day : type.cant_max)];
              }
              return (!isForEdit && !isReadOnly)
                ? ""
                : (type.unidad === "H" ? this.createDateFromHHMM(editData.id_time_per_day) : editData.id_time_per_day);
            }
          }
        },
        {
          field: 'type',
          updates: {
            amountOfDays: (typeValue, allValues) => {
              if (!isForEdit && !isReadOnly) { //el campo no va a cambiar si es editar
                const type = this.getPermissionTypeSelected(typeValue);
                if (type) {
                  let cantMax = type.unidad === "D" ? type.cant_max : 1;

                  permissionDaysArray = [...this.createPermissionDaysArray(cantMax)]
                  if (type.unidad === "H") {
                    return "1";
                  }
                }
                return ""
              } else {
                permissionDaysArray = [...this.createPermissionDaysArray(editData.duration_days)]
                return editData.duration_days;
              }
            }
          }
        },
        {
          field: 'type',
          updates: {
            dateEnd: (typeValue, allValues) => {
              if (isReadOnly) {
                return allValues.dateEnd;
              } else {
                return null
              }
            }
          }
        },
      )

      return (
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          decorators={[calculator]}
          initialValues={initialValues}
          onSubmit={this.validateAndCreate}
          render={({ handleSubmit, form, submitting, pristine, values, active }) => {
            window.setFormValue = form.mutators.setValue;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent style={{ paddingTop: 0 }}>
                  <Grid container direction="column" justify="center">
                    {
                      this.getIsClassC(vacationsTypeArray, values.type) &&
                      <Typography className="whiteText" style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={this.props.handleViewBalance}>
                        Ver saldos
                      </Typography>
                    }
                    <Grid container direction="row" justify="center" item>
                      {
                        !isForEdit && !isReadOnly &&
                        <Grid container direction="column" justify="center" item>
                          <Select
                            disabled={isForEdit || isReadOnly}
                            label={getLocalizedString("requestReceptor")}
                            name={"receptor"}
                            required
                            formControlProps={{
                              margin: "normal",
                            }}>
                            {
                              recipients.permission.map((subItem) => (
                                <MenuItem
                                  className={"whiteText"}
                                  key={subItem.code}
                                  value={subItem.code}>
                                  {subItem.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      }

                      <Select
                        required={!isReadOnly}
                        label={getLocalizedString("permissionType")}
                        disabled={isForEdit || isReadOnly}
                        name={"type"}
                        formControlProps={{
                          margin: "normal",
                        }}>
                        {vacationsTypeArray.map((subItem, index) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.FldValue}
                            value={subItem.FldValue}>
                            {subItem.Descr}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid container direction="row" item>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                          <DatePicker
                            required={!isReadOnly}
                            label={getLocalizedString("startDate")}
                            disabled={isReadOnly}
                            variant="inline"
                            disablePast={!isForEdit}
                            minDate={!isReadOnly && this.calculateNextDay()}
                            format="dd/MM/yyyy"
                            margin="normal"
                            name="date"
                          />
                        </MuiPickersUtilsProvider>
                        {
                          !isReadOnly &&
                          <Grid item xs={12} style={{ height: 40 }}>
                            <Typography
                              className={
                                !dateIsAfterToday(values.date) ? "errorColor" : "whiteText"
                              }
                              variant="caption"
                              gutterBottom>
                              {getLocalizedString("startDateValidation")}
                            </Typography>
                          </Grid>
                        }
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        className="secondFieldPadding"
                        xs={12} sm={6}>
                        {
                          (values.type && this.getPermissionTypeSelected(values.type).unidad === "H") ?
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                              <TimePicker
                                disabled={isReadOnly}
                                required={!isReadOnly}
                                minutesStep={5}
                                label={getLocalizedString("timePerDay")}
                                clearable
                                clearLabel={getLocalizedString("clear")}
                                cancelLabel={getLocalizedString("cancel")}
                                okLabel={getLocalizedString("ok")}
                                name={"timePerDay"}
                                format="HH:mm"
                                margin="normal"
                                ampm={false}
                              />
                            </MuiPickersUtilsProvider>
                            :
                            <Select
                              required={!isReadOnly}
                              disabled={isReadOnly}
                              label={getLocalizedString("timePerDay")}
                              name={"timePerDay"}
                              formControlProps={{
                                margin: "normal",
                              }}>
                              {timesPerDayArray.map((subItem, index) => (
                                <MenuItem
                                  className={"whiteText"}
                                  key={subItem.FldValue}
                                  value={subItem.FldValue}>
                                  {subItem.Descr}
                                </MenuItem>
                              ))}
                            </Select>
                        }
                        {
                          !isReadOnly &&
                          <Grid item xs={12} style={{ height: 40 }}>
                            <Typography
                              style={{ visibility: (values.type && this.getPermissionTypeSelected(values.type).unidad) === "H" ? "visible" : "hidden" }}
                              className={
                                !this.timePerDayIsValid(values) ? "errorColor" : "whiteText"
                              }
                              variant="caption"
                              gutterBottom>
                              {
                                isForEdit
                                  ?
                                  `${getLocalizedString("permissionTimeRange")
                                    .replace(
                                      "{max}", values.type ? String(Math.floor(editData.cant_max)).padStart(2, '0') : `00`
                                    )
                                    .replace(
                                      "{maxMin}", values.type ? this.decToMM(editData.cant_max) : `00`
                                    )
                                    .replace(
                                      "es de 00:10", editData && Number(editData.cant_max) ? "es de 00:10" : `es de 00:00`
                                    )
                                  }.
                                  ${getLocalizedString("permissionTimeNotGreater")}`
                                  :
                                  getLocalizedString("permissionTimeRange")
                                    .replace(
                                      "{max}", values.type ? String(Math.floor(this.getPermissionTypeSelected(values.type).cant_max)).padStart(2, '0') : `00`
                                    )
                                    .replace(
                                      "{maxMin}", values.type ? this.decToMM(this.getPermissionTypeSelected(values.type).cant_max) : `00`
                                    )
                                    .replace(
                                      "es de 00:10", values.type && Number(this.getPermissionTypeSelected(values.type).cant_max) ? "es de 00:10" : `es de 00:00`
                                    )
                              }
                            </Typography>
                          </Grid>
                        }
                      </Grid>
                    </Grid>

                    <Grid container direction="row" item>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12} sm={6}>
                        <Select
                          required={!isReadOnly}
                          disabled={isReadOnly}
                          label={getLocalizedString("amountOfDays")}
                          name={"amountOfDays"}
                          formControlProps={{
                            margin: "normal",
                          }}>
                          {
                            permissionDaysArray.map((subItem, index) => (
                              <MenuItem
                                className={"whiteText"}
                                key={subItem.FldValue}
                                value={subItem.FldValue}>
                                {subItem.Descr}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        item
                        className="secondFieldPadding"
                        xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                          <DatePicker
                            label={getLocalizedString("endDate")}
                            disabled
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            name="dateEnd"
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>

                    {
                      isForEdit && !isReadOnly &&
                      <Grid container style={{ paddingBottom: 15 }}>
                        <Typography
                          style={{ paddingTop: 5 }}
                          className={"whiteText"}
                          variant="caption"
                          gutterBottom>
                          {getLocalizedString("howToSelectMoreDays")}
                        </Typography>
                      </Grid>
                    }

                    <Grid
                      style={{ paddingTop: 16 }}
                      container
                      direction="row"
                      justify="flex-start"
                      item>
                      <TextField
                        required={isForEdit && !isReadOnly}
                        label={getLocalizedString("detail")}
                        disabled={isReadOnly}
                        id="fieldToFocus"
                        name={"descripcion"}
                        margin={"none"}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, 1024);
                        }}
                        multiline={true}
                        rows={1}
                        rowsMax={3}
                      />
                      {
                        !isReadOnly &&
                        <Typography
                          style={{ paddingTop: 5 }}
                          className={!this.textIsValid(values) ? "errorColor" : "whiteText"}
                          variant="caption"
                          gutterBottom>
                          {!isForEdit && `${getLocalizedString("optionalField")}. `}
                          {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                            }. ${getLocalizedString("maxCharRequests")}.`}
                        </Typography>
                      }
                    </Grid>

                  </Grid>
                </DialogContent>
                {
                  isReadOnly &&
                  <div style={{ marginTop: 32 }} />
                }
                {
                  !isReadOnly &&
                  <DialogActions>
                    <ButtonDialogAction
                      onClick={this.handleClose}
                      text={getLocalizedString("disagree")}
                    />
                    <ButtonDialogAction
                      type="submit"
                      isAccept
                      text={getLocalizedString("agree")}
                    />
                  </DialogActions>
                }
              </form>
            )
          }}
        />
      );
    } else {
      return <CantCreateRequestMessage />
    }
  }
}
export default NewPermissionContent;
