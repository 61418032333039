import React from "react";
import { DialogTitle, Dialog, DialogContent, Typography } from "@material-ui/core";
import { decimalToTime } from "@icarius-utils/date";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";
import CommonPage from "@icarius-common/commonPage";

const SecondGridDialogIsOpen = (props) => {
  const {
    open,
    data,
    dateFormat,
    locale,
    handleClose,
  } = props;

  const getCreditLabel = (value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue === 0) { return "0,00 / 00:00 horas y minutos" }

    const time = decimalToTime(parsedValue);
    const formattedValue = formatNumberOrReturnUndefined(parsedValue, 2, 2);

    return `${formattedValue} / ${time} horas y minutos`;
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {'Cuenta corriente de permisos en horas'}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Typography className="whiteText" style={{ marginBottom: 10 }}>
          {`Saldo actual: ${data.length ? getCreditLabel(data[0]['Saldo al movimiento']) : getCreditLabel(0)} (Crédito)`}
        </Typography>
        <CommonPage
          gridTitle={"Cuenta corriente de permisos en horas"}
          columnDefPage={'myRequestsViewBalance'}
          rowData={data}
          dateFormat={dateFormat}
          locale={locale}
          gridHeight={'75vh'}
          hasSelectAll
          hasExpand
          hasHelp
          ignoreProcessesbyPath
        />
      </DialogContent>
    </Dialog>
  );
}

export default SecondGridDialogIsOpen;
