import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import NineBoxIndicator from "@icarius-common/nineBoxIndicator";
import { useSelector } from "react-redux";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import QR from "@icarius-common/qr";
import Likes from "@icarius-common/likes";
import ProfileImg from "@icarius-common/profileImg";

const CustomButton = withStyles({
    root: {
        color: "var(--mainText) !important",
        textTransform: "none",
        backgroundColor: "var(--secondaryBackgroundColor)",
        width: "100%",
    }
})(Button);

const UserSummary = (props) => {

    const {
        isPhotoModificable,
        employee,
        enableQueriesButton,
        enableCertificates,
        enableReceipts,
        handleFirstButton,
        handleSecondButton,
        handleQueriesButton,
        handleOpenChangeMyPersonalPhotoDialog,
    } = props;

    const color = useSelector(getAppColor);
    const societySelected = useSelector(getSocietySelected);

    const qr =
        employee &&
        "BEGIN:VCARD\n" +
        "VERSION:3.0\n" +
        "N:" + employee["APELLIDO Y NOMBRES"] + "\n" +
        "ORG:" + societySelected.name + "\n" +
        "TITLE:" + employee["CARGO"] + "\n" +
        "TEL:" + employee["CELULAR"] + "\n" +
        "EMAIL:" + employee["EMAIL"] + "\n" +
        "END:VCARD\n";


    const buttonSize = 12 / ([enableCertificates, enableReceipts, enableQueriesButton].filter((item) => item).length || 1);

    const hasEmployeeData = Boolean(Object.keys(employee).length);

    return (
        <Grid container item xs={12} alignItems="center" spacing={2}>
            <Grid container alignItems="center" justify="center" item sm={12} md={2}>
                <div className={"photo-container"}>
                    <ProfileImg
                        image={employee["FOTOGRAFIA"]}
                        size={100}
                        editable={isPhotoModificable}
                        handleChange={handleOpenChangeMyPersonalPhotoDialog}
                    />
                </div>
            </Grid>
            <Grid container direction="column" item sm={12} md={8}>
                <Typography
                    style={{
                        fontWeight: 200,
                        fontSize: '20px',
                        lineHeight: "28px",
                    }}
                    className={"whiteText"}
                    variant="subtitle1"
                >
                    {employee["APELLIDO Y NOMBRES"]}
                </Typography>
                {
                    employee && employee["MANAGER"] && employee["MANAGER"] !== "-" &&
                    <Typography
                        style={{
                            color: color,
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: "28px",
                        }}
                    >
                        {"Manager: " + employee["MANAGER"]}
                    </Typography>
                }
                {
                    Boolean(employee["nine_box"]) &&
                    <div style={{ paddingTop: 10 }}>
                        <NineBoxIndicator data={employee["nine_box"]} />
                    </div>
                }
                <div style={{ paddingTop: 10 }}>
                    <Likes value={employee["OPINION"]} />
                </div>
            </Grid>
            {
                hasEmployeeData &&
                <Grid container item sm={12} md={2} justify="center" alignItems="center">
                    <QR value={qr} hideLabel />
                </Grid>
            }
            {
                hasEmployeeData &&
                <Grid container item xs={12} justify="center" alignItems="center" spacing={2}>
                    {
                        enableCertificates &&
                        <Grid container item xs={12} md={buttonSize} justify="center">
                            <CustomButton
                                onClick={handleFirstButton}
                                variant={"contained"}
                            >
                                {getLocalizedString("certificates")}
                            </CustomButton>
                        </Grid>
                    }
                    {
                        enableReceipts &&
                        <Grid container item xs={12} md={buttonSize} justify="center">
                            <CustomButton
                                onClick={handleSecondButton}
                                variant={"contained"}
                            >
                                {getLocalizedString("receipts")}
                            </CustomButton>
                        </Grid>
                    }
                    {
                        enableQueriesButton &&
                        <Grid container item xs={12} md={buttonSize} justify="center">
                            <CustomButton
                                onClick={handleQueriesButton}
                                variant={"contained"}
                            >
                                {'Consultas'}
                            </CustomButton>
                        </Grid>
                    }
                </Grid>
            }
        </Grid>
    )
}

export default UserSummary;