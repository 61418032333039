import {
  GET_MY_REQUESTS,
  GET_MY_REQUESTS_FULFILLED,
  GET_MY_REQUESTS_REJECTED,

  SET_COLABORATOR_APPEAL,
  SET_COLABORATOR_APPEAL_FULFILLED,
  SET_COLABORATOR_APPEAL_REJECTED,

  MODIFY_REQUEST_STATUS,
  MODIFY_REQUEST_STATUS_FULFILLED,
  MODIFY_REQUEST_STATUS_REJECTED,

} from "./actionTypes";
import { requestsIds } from "@icarius-utils/requestsIds";

const initialState = {
  order: "APELLIDO Y NOMBRE",
  orderDirection: "asc",

  helpDocumentation: "",
  likeRequest: false,

  recipients: {},
  myRequests: [],
  gettingMyRequests: false,
  vacationCalculationData: {},
  permissionCalculationData: {},
  advanceCalculationData: {},
  typesPermission: [],
  typesAdvances: [],
  decimalSeparator: null,
  currencyLocalization: null,
  dateFormat: null,
  settingRequest: false,
  usedDates: [],
  ccHoursAppeals: [],
  vacationsEnabled: false,
  permissionsEnabled: false,
  loansEnabled: false,
  startHolidaysOnNonWorkingDay: true,
  allowHalfDayHolidays: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_REQUESTS:
      return { ...state, gettingMyRequests: true };
    case GET_MY_REQUESTS_REJECTED:
      return { ...state, gettingMyRequests: false };
    case GET_MY_REQUESTS_FULFILLED:
      return {
        ...state,
        gettingMyRequests: false,
        recipients: action.payload.recipients,
        myRequests: action.payload.myRequests,
        vacationCalculationData: action.payload.vacationCalculationData,
        permissionCalculationData: action.payload.permissionCalculationData,
        advanceCalculationData: action.payload.advanceCalculationData,
        typesPermission: action.payload.typesPermission,
        typesAdvances: action.payload.typesAdvances,
        decimalSeparator: action.payload.decimalSeparator,
        currencyLocalization: action.payload.currencyLocalization,
        dateFormat: action.payload.dateFormat,
        usedDates: action.payload.usedDates,
        ccHoursAppeals: action.payload.ccHoursAppeals,
        vacationsEnabled: action.payload.vacationsEnabled,
        permissionsEnabled: action.payload.permissionsEnabled,
        loansEnabled: action.payload.loansEnabled,
        startHolidaysOnNonWorkingDay: action.payload.startHolidaysOnNonWorkingDay,
        allowHalfDayHolidays: action.payload.allowHalfDayHolidays,
      };

    case SET_COLABORATOR_APPEAL:
      return { ...state, settingRequest: true };
    case SET_COLABORATOR_APPEAL_FULFILLED: {
      let auxVacation = state.vacationCalculationData;
      let auxPermission = state.permissionCalculationData;
      let auxAdvance = state.advanceCalculationData;

      switch (action.payload.type) {
        case requestsIds.id_type.VAC:
          auxVacation.can_user_appeal = false;
          break;
        case requestsIds.id_type.PERM:
          auxPermission.can_user_appeal = false;
          break;
        case requestsIds.id_type.ANT:
          auxAdvance.can_user_appeal = false;
          break;
        default: break;
      }


      let newArray = state.myRequests.length > 0 ? [...state.myRequests] : [];
      if (!action.payload.includesHistory) {
        newArray.unshift(action.payload.data);
      }

      return { ...state, usedDates: action.payload.usedDates || state.usedDates, settingRequest: false, vacationCalculationData: auxVacation, permissionCalculationData: auxPermission, advanceCalculationData: auxAdvance, myRequests: newArray };
    }
    case SET_COLABORATOR_APPEAL_REJECTED:
      return { ...state, settingRequest: false };


    case MODIFY_REQUEST_STATUS:
      return { ...state, settingRequest: true };
    case MODIFY_REQUEST_STATUS_FULFILLED:
      let newArray = [...state.myRequests];
      if (action.payload.shouldRemoveFromArray) {
        newArray = state.myRequests.filter(request => request.code !== action.payload.data.code);
      }

      let auxVacation = state.vacationCalculationData;
      let auxPermission = state.permissionCalculationData;
      let auxAdvance = state.advanceCalculationData;

      switch (action.payload.type) {
        case requestsIds.id_type.VAC:
          auxVacation.can_user_appeal = action.payload.canUserAppeal;
          break;
        case requestsIds.id_type.PERM:
          auxPermission.can_user_appeal = action.payload.canUserAppeal;
          break;
        case requestsIds.id_type.ANT:
          auxAdvance.can_user_appeal = action.payload.canUserAppeal;
          break;

        default: break;
      }
      return { ...state, usedDates: action.payload.usedDates || state.usedDates, settingRequest: false, myRequests: newArray, vacationCalculationData: auxVacation, permissionCalculationData: auxPermission, advanceCalculationData: auxAdvance };
    case MODIFY_REQUEST_STATUS_REJECTED:
      return { ...state, settingRequest: true };

    default:
      return state;
  }
}
