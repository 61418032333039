import React from "react";
import {
  Typography,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  TextField as TextFieldNoForm
} from "@material-ui/core";
import { TextField, Select, DatePicker } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getTomorrow, dateIsAfterToday, createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import CantCreateRequestMessage from "./cantCreateRequestMessage";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import NumberFormatCustom from './numberFormatCustom';
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import createDecorator from 'final-form-calculate'

class NewLoanContent extends React.Component {

  componentDidMount() {
    if (!this.props.isReadOnly && !this.props.isForEdit && Object.keys(this.props.recipients.advance).length === 0) {
      this.props.handleNoRecipient();
      this.handleClose();
    }
  }

  handleClose = (shouldReloadTable) => {
    if (this.props.isForEdit && shouldReloadTable) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  textIsValid = (values) => {
    if (this.props.isForEdit)
      return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 1024;
    else
      return values.descripcion === undefined || (values.descripcion !== undefined && values.descripcion.length >= 0 && values.descripcion.length <= 1024);
  };

  dataIsComplete = (values) => {
    if (!this.props.isForEdit && !this.props.isReadOnly && !values.receptor) return false;
    if (values.type && this.isAmountInRange(values) && this.textIsValid(values) && dateIsAfterToday(values.date))
      return true;
    return false;
  };

  calculateSalaryPercentage = (money, salary) => {
    if (!money) return 0;
    return (parseFloat(money) / salary * 100).toFixed(2);
  }

  warnAboutMoney = (percentage, moneyIsOk) => {
    if (moneyIsOk) {
      this.props.dispatch(openSnackbarAction({
        msg: `Porcentaje del valor solicitado sobre el sueldo: ${percentage}`,
        severity: "info"
      }));
    } else {
      this.props.dispatch(openSnackbarAction({
        msg: `El valor que esta solicitando excede el porcentaje máximo permitido sobre su sueldo (${percentage}%)`,
        severity: "error"
      }));
    }
  }

  validateMoneyAndWarn = (money) => {
    const { advanceCalculationData, isForEdit, editData } = this.props;

    const salary = isForEdit ? editData.current_salary : advanceCalculationData.salary_employee;

    const percentage = this.calculateSalaryPercentage(money, salary);
    const maxPercentage = advanceCalculationData.percent_max || 999999999999999;
    const moneyIsOk = percentage <= maxPercentage;

    this.warnAboutMoney(moneyIsOk ? percentage : advanceCalculationData.percent_max, moneyIsOk);

    return moneyIsOk;
  }

  validateAndCreate = values => {
    if (this.textIsValid(values) && this.dataIsComplete(values)) {
      if (this.validateMoneyAndWarn(values.amount)) {
        const request = {
          code: this.props.requestCode,
          id_status: "3",
          id_type: "ANT",
          id_subtype: values.type,
          date: formatDate(values.date),
          value: values.amount.toString(),
          comments: values.descripcion,
          receptor: values.receptor,
        }

        if (!this.props.isForEdit) {
          delete request.code;
          delete request.id_status;
        } else {
          delete request.id_type;
          delete request.id_subtype;
          delete request.receptor;
        }

        this.handleRequest(request);
      }
    }
    else {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  handleRequest = request => {
    this.props.handleRequest(request);
    if (!this.props.isForEdit) {
      this.handleClose(true);
    }
  };

  isAmountInRange = (values) => {
    return values.amount && values.amount >= 1;
  }

  createTypesArray = () => {
    let newArray = this.props.typesAdvances.map((type) => {
      return { FldValue: type.code, Descr: type.name };
    })
    return newArray;
  }

  render() {
    const { advanceCalculationData, editData, isForEdit, isReadOnly, recipients } = this.props;

    let initialValues;
    const recipientInitialValue = (!isForEdit && !isReadOnly) ? recipients.advance.length === 1 ? recipients.advance[0].code : "" : "";

    if (!isForEdit && !isReadOnly) {
      initialValues = {
        date: "",
        type: "",
        descripcion: "",
        amount: "",
        receptor: recipientInitialValue, //si tiene un valor solo, inicializar con ese
      };
    } else {
      initialValues = {
        date: createDateFromDDMMYYYY(editData.end_date),
        descripcion: editData.comments,
        type: editData.id_subtype,
        timePerDay: parseFloat(editData.id_time_per_day),
        amount: editData.value.toString(),
      };
    }

    // si se cambia el type, resetear receptor
    const calculator = createDecorator(
      {
        field: 'type',
        updates: {
          receptor: () => {
            return '';
          }
        }
      },
    )

    const getDetailStyle = () => {
      if (isReadOnly && !isForEdit) {
        return { marginTop: 0 };
      }
      if (!isReadOnly && isForEdit) {
        return { marginTop: 16 };
      }
      if (isReadOnly && isForEdit && editData?.percentage) {
        return { marginTop: 24 };
      }
      return { marginTop: 0 };
    }

    const typeArray = this.createTypesArray();
    if (isForEdit || advanceCalculationData.can_user_appeal || isReadOnly) {
      return (
        <Form
          initialValues={initialValues}
          onSubmit={this.validateAndCreate}
          decorators={[calculator]}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent style={{ paddingTop: 0 }}>
                <Grid container direction="column" justify="center">
                  <Grid container direction="row" justify="center" item>
                    <Select
                      required={!isReadOnly}
                      disabled={isForEdit || isReadOnly}
                      label={getLocalizedString("loanType")}
                      name={"type"}
                      formControlProps={{
                        margin: "normal",
                      }}
                    >
                      {
                        typeArray.map((subItem) =>
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.FldValue}
                            value={subItem.FldValue}>
                            {subItem.Descr}
                          </MenuItem>
                        )}
                    </Select>
                    {
                      !isForEdit && !isReadOnly &&
                      <Grid container direction="column" justify="center" item>
                        <Select
                          disabled={isForEdit || isReadOnly || !Boolean(values.type)}
                          label={getLocalizedString("requestReceptor")}
                          name={"receptor"}
                          required
                          formControlProps={{
                            margin: "normal",
                          }}
                        >
                          {
                            !Boolean(values.type) &&
                            <MenuItem
                              className={"whiteText"}
                              key={''}
                              value={''}>
                              {''}
                            </MenuItem>
                          }
                          {
                            recipients.advance[values.type]?.map((subItem) => (
                              <MenuItem
                                className={"whiteText"}
                                key={subItem.code}
                                value={subItem.code}>
                                {subItem.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    }
                  </Grid>
                  <Grid container direction="row" item alignItems="center">
                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} style={{ marginBottom: 16 }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} >
                        <DatePicker
                          required={!isReadOnly}
                          label={getLocalizedString("expectedDateOfPay")}
                          disabled={isReadOnly}
                          style={{ width: window.innerWidth > 959 ? "calc(100% - 15px)" : "100%" }}
                          variant="inline"
                          disablePast
                          minDate={getTomorrow()}
                          format="dd/MM/yyyy"
                          margin="normal"
                          name="date"
                        />
                      </MuiPickersUtilsProvider>
                      {
                        !isReadOnly &&
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              className={!dateIsAfterToday(values.date) ? "errorColor" : "whiteText"}
                              variant="caption"
                              gutterBottom>
                              {getLocalizedString("startDateValidation")}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid container direction="row" alignItems="flex-end" item className="secondFieldPadding" xs={12} sm={6}>
                      <TextField
                        required={!isReadOnly}
                        label={getLocalizedString("amountLoanRequested")}
                        disabled={isReadOnly}
                        name={"amount"}
                        InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
                        margin={"none"}
                        style={{ marginBottom: isReadOnly ? 8 : 0 }}
                      />
                      {
                        !isReadOnly &&
                        <Grid container style={{ marginTop: 8 }}>
                          <Grid item xs={12}>
                            <Typography
                              className={!this.isAmountInRange(values) ? "errorColor" : "whiteText"}
                              variant="caption"
                              gutterBottom>
                              {'Valor minimo 1'}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  {
                    editData?.percentage &&
                    <Grid container direction="column" justify="center" item>
                      <TextFieldNoForm
                        label={'Porcentaje del valor solicitado sobre el sueldo'}
                        disabled
                        value={
                          `${isReadOnly ? editData?.percentage : this.calculateSalaryPercentage(values.amount, editData.current_salary)}%`
                        }
                        margin={"none"}
                      />
                    </Grid>
                  }
                  <Grid style={getDetailStyle()} container direction="row" justify="flex-start" item>
                    <TextField
                      required={isForEdit && !isReadOnly}
                      label={getLocalizedString("detail")}
                      disabled={isReadOnly}
                      id="fieldToFocus"
                      name={"descripcion"}
                      margin={"none"}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 1024);
                      }}
                      multiline={true}
                      rows={1}
                      rowsMax={3}
                    />
                    {
                      !isReadOnly &&
                      <Typography
                        style={{ paddingTop: 5 }}
                        className={!this.textIsValid(values) ? "errorColor" : "whiteText"}
                        variant="caption"
                        gutterBottom>
                        {!isForEdit && `${getLocalizedString("optionalField")}. `}
                        {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                          }. ${getLocalizedString("maxCharRequests")}.`}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              </DialogContent>
              {
                isReadOnly &&
                <div style={{ marginTop: 32 }} />
              }
              {
                !isReadOnly &&
                <DialogActions>
                  <ButtonDialogAction onClick={this.handleClose} text={getLocalizedString("disagree")} />
                  <ButtonDialogAction type="submit" isAccept text={getLocalizedString("agree")} />
                </DialogActions>
              }
            </form>
          )}
        />
      );
    } else {
      return <CantCreateRequestMessage />
    }
  }
}
export default NewLoanContent;
