import { getLocalizedString } from "@icarius-localization/strings";
import React from "react";
import { connect } from "react-redux";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";


class CancelDialog extends React.Component {
    render() {
        const { open } = this.props;
        const initialValues = {
            comment: "",
        };

        return (
            <Dialog
                TransitionComponent={DialogTransition}
                PaperComponent={PaperDraggable}
                open={open}
                maxWidth={"xs"}
                fullWidth={true}>
                <div className={"dialog-container"}>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {getLocalizedString("cancelRequestTitle")}
                        <DialogTitleDivider />
                    </DialogTitle>
                    <CloseIcon className={"dialog-close-icon icon"} onClick={this.handleClose} />
                    <DialogContent style={{paddingTop: 0}}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Form
                                    onSubmit={this.validateAndConfirm}
                                    initialValues={initialValues}
                                    render={({ handleSubmit, form, submitting, pristine, values, active }) => (
                                        <form onSubmit={handleSubmit} noValidate>
                                            <TextField
                                                name={"comment"}
                                                margin={"none"}
                                                onInput={e => {
                                                    e.target.value = e.target.value.toString().slice(0, 1024);
                                                }}
                                                multiline={true}
                                                rows={1}
                                                label={getLocalizedString("insertComment")}
                                                rowsMax={3}
                                            />
                                            <Typography
                                                style={{ paddingTop: 5 }}
                                                className={"whiteText"}
                                                variant="caption"
                                                gutterBottom>
                                                {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${values.comment ? values.comment.length : 0}. ${
                                                    getLocalizedString("maxCharRequests")
                                                    }.`}
                                            </Typography>
                                            <DialogActions>
                                                <ButtonDialogAction
                                                    type="submit"
                                                    isAccept
                                                    text={getLocalizedString("submit")}
                                                />
                                            </DialogActions>
                                        </form>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    textIsInvalid = values => {
        return values.comment && values.comment.length > 1024;
    };

    validateAndConfirm = values => {
        if (this.textIsInvalid(values)) return;
        this.handleConfirm(values);
    };

    handleConfirm = (values) => {
        const request = {
            code: this.props.code,
            id_status: "2",
            comments: values.comment,
        }

        this.props.handleAccept(request);
        this.handleClose();

    };

    handleClose = () => {
        this.props.handleCloseDialog && this.props.handleCloseDialog();
    };
}

export default connect()(CancelDialog);
