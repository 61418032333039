import NAME from "./constants";

export const GET_PERSONAL_DATA = NAME + "/GET_PERSONAL_DATA";
export const GET_PERSONAL_DATA_REJECTED = NAME + "/GET_PERSONAL_DATA_REJECTED";
export const GET_PERSONAL_DATA_FULFILLED = NAME + "/GET_PERSONAL_DATA_FULFILLED";

export const MODIFY_PERSONAL_DATA = NAME + "/MODIFY_PERSONAL_DATA";
export const MODIFY_PERSONAL_DATA_REJECTED = NAME + "/MODIFY_PERSONAL_DATA_REJECTED";
export const MODIFY_PERSONAL_DATA_FULFILLED = NAME + "/MODIFY_PERSONAL_DATA_FULFILLED";

export const MODIFY_MY_PHOTO = NAME + "/MODIFY_MY_PHOTO";
export const MODIFY_MY_PHOTO_REJECTED = NAME + "/MODIFY_MY_PHOTO_REJECTED";
export const MODIFY_MY_PHOTO_FULFILLED = NAME + "/MODIFY_MY_PHOTO_FULFILLED";

export const GET_REGIONS_BY_COUNTRY = NAME + "/GET_REGIONS_BY_COUNTRY";
export const GET_REGIONS_BY_COUNTRY_REJECTED = NAME + "/GET_REGIONS_BY_COUNTRY_REJECTED";
export const GET_REGIONS_BY_COUNTRY_FULFILLED = NAME + "/GET_REGIONS_BY_COUNTRY_FULFILLED";

export const GET_PROVINCES_BY_REGION = NAME + "/GET_PROVINCES_BY_REGION";
export const GET_PROVINCES_BY_REGION_REJECTED = NAME + "/GET_PROVINCES_BY_REGION_REJECTED";
export const GET_PROVINCES_BY_REGION_FULFILLED = NAME + "/GET_PROVINCES_BY_REGION_FULFILLED";

export const GET_COUNTIES_BY_PROVINCE = NAME + "/GET_COUNTIES_BY_PROVINCE";
export const GET_COUNTIES_BY_PROVINCE_FULFILLED = NAME + "/GET_COUNTIES_BY_PROVINCE_FULFILLED";
export const GET_COUNTIES_BY_PROVINCE_REJECTED = NAME + "/GET_COUNTIES_BY_PROVINCE_REJECTED";

export const GET_LOCATION = NAME + "/GET_LOCATION";
export const GET_LOCATION_FULFILLED = NAME + "/GET_LOCATION_FULFILLED";
export const GET_LOCATION_REJECTED = NAME + "/GET_LOCATION_REJECTED";

export const RESET_DATA = NAME + "/RESET_DATA";