import * as actionTypes from "./actionTypes";

const initialState = {
  myPersonalData: {},
  avatar: "",
  editableFields: [],
  enableCertificates: false,
  enableReceipts: false,
  sectionsToHide: [],
  fieldsToHide: [],

  counties: [],
  provinces: [],
  regions: [],
  receipts: [],

  isLoading: false,
  isLoadingLocation: false,
  modifyingMyPersonalData: false,
  modifyingPersonalPhoto: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_DATA:
      return initialState;
    case actionTypes.GET_PERSONAL_DATA:
      return { ...state, isLoading: true, }
    case actionTypes.GET_PERSONAL_DATA_FULFILLED:
      return {
        ...state,
        editableFields: action.payload.editableFields,
        myPersonalData: action.payload.data,
        avatar: action.payload.avatar,
        dateFormat: action.payload.dateFormat,
        enableCertificates: action.payload.enableCertificates,
        enableReceipts: action.payload.enableReceipts,
        sectionsToHide: action.payload.sectionsToHide,
        fieldsToHide: action.payload.fieldsToHide,
        isLoading: false,
      };
    case actionTypes.GET_PERSONAL_DATA_REJECTED:
      return { ...state, isLoading: false }

    case actionTypes.MODIFY_MY_PHOTO:
      return { ...state, modifyingPersonalPhoto: true };
    case actionTypes.MODIFY_MY_PHOTO_FULFILLED:
      return {
        ...state,
        modifyingPersonalPhoto: false,
        myPersonalData: {
          ...state.myPersonalData,
          FOTOGRAFIA: action.payload,
        },
      };
    case actionTypes.MODIFY_MY_PHOTO_REJECTED:
      return { ...state, modifyingPersonalPhoto: false };

    case actionTypes.MODIFY_PERSONAL_DATA:
      return { ...state, modifyingMyPersonalData: true };
    case actionTypes.MODIFY_PERSONAL_DATA_FULFILLED: {
      let newData = {
        ...state.myPersonalData,
        ...action.payload,
      };

      return {
        ...state,
        modifyingMyPersonalData: false,
        myPersonalData: newData,
      };
    }
    case actionTypes.MODIFY_PERSONAL_DATA_REJECTED:
      return { ...state, modifyingMyPersonalData: false };

    case actionTypes.GET_LOCATION:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_LOCATION_FULFILLED:
      return {
        ...state,
        isLoadingLocation: false,
        counties: action.payload.counties,
        provinces: action.payload.provinces,
        regions: action.payload.regions,
      };
    case actionTypes.GET_LOCATION_REJECTED:
      return { ...state, isLoadingLocation: false };

    case actionTypes.GET_COUNTIES_BY_PROVINCE:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_COUNTIES_BY_PROVINCE_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        counties: action.payload,
      };
    }
    case actionTypes.GET_COUNTIES_BY_PROVINCE_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        counties: [],
      };
    }

    case actionTypes.GET_PROVINCES_BY_REGION:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_PROVINCES_BY_REGION_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        provinces: action.payload,
        counties: [],
      };
    }
    case actionTypes.GET_PROVINCES_BY_REGION_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        provinces: [],
        counties: [],
      };
    }

    case actionTypes.GET_REGIONS_BY_COUNTRY:
      return { ...state, isLoadingLocation: true };
    case actionTypes.GET_REGIONS_BY_COUNTRY_FULFILLED: {
      return {
        ...state,
        isLoadingLocation: false,
        regions: action.payload,
        provinces: [],
        counties: [],
      };
    }
    case actionTypes.GET_REGIONS_BY_COUNTRY_REJECTED: {
      return {
        ...state,
        isLoadingLocation: false,
        regions: [],
        provinces: [],
        counties: [],
      };
    }
    default:
      return state;
  }
}
