import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMyScheduleAction, getMyScheduleAction } from '../actions';
import {
  getIsLoading,
  getData,
  getSchedules,
  getOpenCalendar,
  getMinDates,
  getWeeklyHours,
} from '../selectors';
import { formatDateYYYYMMDD } from '@icarius-utils/date';

const useMySchedule = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getData);
  const schedules = useSelector(getSchedules);
  const openCalendar = useSelector(getOpenCalendar);
  const minDates = useSelector(getMinDates);
  const weeklyHours = useSelector(getWeeklyHours);

  useEffect(() => {
    dispatch(getMyScheduleAction());
  }, [dispatch])

  const handleSave = (data) => {
    const dataToSend = data
      .filter(item => item.type.includes('request'))
      .map(item => {
        return ({
          resource: item.resource,
          scheduleId: item.scheduleId,
          start: formatDateYYYYMMDD(item.start),
          title: item.title,
          type: item.type,
        })
      });

    dispatch(createMyScheduleAction({ data: dataToSend }));
  }  

  return {
    isLoading,
    data,
    schedules,
    openCalendar,
    minDates,
    weeklyHours,
    handleSave,
  }
}

export default useMySchedule;