import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  getMyRequestsRows,
  setColaboratorAppeal,
  changeRequestStatus,
} from "../actions";
import {
  loading,
  getRecipients,
  getMyRequests,
  getDateFormat,
  getUsedDates,
  getLocale,
  getVacationCalculationData,
  getDecimalSeparator,
  getTypesPermission,
  getPermissionCalculationData,
  getAdvanceCalculationData,
  getCCHoursAppeals,
  getTypesAdvances,
  getVacationsEnabled,
  getPermissionsEnabled,
  getLoansEnabled,
  getStartHolidaysOnNonWorkingDay,
  getAllowHalfDayHolidays,
} from "../selectors";
import { getLoginResponse } from "../../login/selectors";
import { getAppColor, getUserData } from "../../../app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import "@icarius-assets/css/grid-dark.scss";
import "@icarius-assets/css/grid-light.scss";
import { LocalAtmIcon, DirectionsRunIcon, FlightTakeoffIcon } from "@icarius-icons";
import RequestDialog from "./modalRequests/RequestDialog";
import SecondGridDialog from "./secondGridDialog";
import CancelDialog from "./cancelDialog";
import ReplyDialog from "../../myPending/components/dialogs/replyDialog";
import { requestsIds } from "@icarius-utils/requestsIds";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ViewBalanceDialog from "./viewBalanceDialog";

class MyRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      context: this,
      typeToOpen: "",
      includesHistory: false,
      currentRequestWorkflow: [],
      secondGridDialogIsOpen: false,
      cancelDialogIsOpen: false,
      answerDialogIsOpen: false,
      myVacationsDialogIsOpen: false,
      isReadOnly: false,
      viewBalanceIsOpen: false,
      comesFromElsewhere: false,
      didInitialFetch: false,
    };
  }

  componentDidMount() {
    if (this.props.userData.level === "C") {
      this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
    }
  }

  componentDidUpdate() {
    //si viene un type desde el path, abrir el modal de abm de ese type, pero antes, hacer el get, porque quizas intenta hacerlo antes de tener los datos
    const type = new URLSearchParams(this.props.location.search).get("type");
    if (type) {
      // limpiar el type del path
      this.props.history.replace({
        pathname: paths.myRequests,
        search: '',
      });

      this.props.dispatch(getMyRequestsRows(this.state.includesHistory))
        .then((resp) => {
          if (resp.status === 200) {
            this.setState({ comesFromElsewhere: true }, () => {
              this.handleOpenRequest(type);
            });
          }
        })
    }
  }

  shouldOpenRequestDialog = (requestType, callback) => {
    let validationData;

    switch (requestType) {
      case requestsIds.id_type.VAC:
        validationData = this.props.vacationCalculationData;
        break;
      case requestsIds.id_type.PERM:
        validationData = this.props.permissionCalculationData;
        break;
      case requestsIds.id_type.ANT:
        validationData = this.props.advanceCalculationData;
        break;
      default: return false;
    }

    if (validationData.can_user_appeal) {
      return true;
    } else {
      callback && callback();
      return false;
    }
  }

  handleOpenViewBalance = () => {
    this.setState({ viewBalanceIsOpen: true });
  }

  handleCloseViewBalance = () => {
    this.setState({ viewBalanceIsOpen: false });
  }

  handleOpenRequest = (type) => {
    if (this.shouldOpenRequestDialog(type)) {
      this.setState({ typeToOpen: type });
    } else {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("cantCreateNewRequest"), severity: "error" }));
    }
  }

  handleCloseRequest = () => {
    this.setState({ typeToOpen: "", comesFromElsewhere: false });
  };

  handleNoRecipient = () => {
    this.props.dispatch(openDialogAction({
      msg: "No se encontró a ningún receptor para la atención de su solicitud. La misma no puede enviarse. Informe sobre esta situación a Recursos Humanos",
      title: getLocalizedString("atention"),
      acceptOnly: true
    }
    ))
  }

  render() {
    const {
      myRequestsRows,
      loadingRows,
      exportingGrid,
      color,
      dateFormat,
      locale,
      vacationCalculationData,
      permissionCalculationData,
      advanceCalculationData,
      typesPermission,
      typesAdvances,
      usedDates,
      recipients,
      ccHoursAppeals,
      vacationsEnabled,
      permissionsEnabled,
      loansEnabled,
      startHolidaysOnNonWorkingDay,
      allowHalfDayHolidays,
    } = this.props;

    const vacationsItem = () =>
      vacationsEnabled &&
      <CustomIconButton
        title={getLocalizedString("holidays")}
        onClick={() => this.handleOpenRequest(requestsIds.id_type.VAC)}
      >
        <FlightTakeoffIcon />
      </CustomIconButton>

    const permissionsItem = () =>
      permissionsEnabled &&
      <CustomIconButton
        title={getLocalizedString("permissions")}
        onClick={() => this.handleOpenRequest(requestsIds.id_type.PERM)}
      >
        <DirectionsRunIcon />
      </CustomIconButton>

    const loansItem = () =>
      loansEnabled &&
      <CustomIconButton
        title={getLocalizedString("loanRequestTitle")}
        onClick={() => this.handleOpenRequest(requestsIds.id_type.ANT)}
      >
        <LocalAtmIcon />
      </CustomIconButton>

    const helpItems = [
      <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Solicitudes Colaborador.pdf"} text={getLocalizedString("requestsColabInstructivo")} type={"file"} />,
      <MenuItemWithIcon key={"2"} value={"https://www.youtube.com/embed/8fj60nDAJ4A"} text={getLocalizedString("requestsTitle")} type={"video"} />,
    ]

    return (
      <CommonPage
        helpItems={helpItems}
        rowData={myRequestsRows}
        title={getLocalizedString("myRequests")}
        gridTitle={getLocalizedString("myRequests")}
        locale={locale}
        dateFormat={dateFormat}
        columnDefPage={paths.myRequests}
        handleRowClick={this.handleRowClick}
        isLoading={loadingRows || exportingGrid}
        menuItems={[vacationsItem, permissionsItem, loansItem]}
        hasHelp
        hasExpand
        hasSelectAll
        hasSwitch
        switchValue={this.state.includesHistory}
        handleSwitchClick={this.handleSwitchClick}
        switchLabelOn={getLocalizedString("requestsInHistory")}
        switchLabelOff={getLocalizedString("requestsInProgress")}
      >
        {
          this.state.secondGridDialogIsOpen && !loadingRows && !exportingGrid &&
          <SecondGridDialog
            open={this.state.secondGridDialogIsOpen}
            data={this.state.currentRequestWorkflow}
            dateFormat={dateFormat}
            locale={locale}
            handleOpenAnswerDialog={this.handleOpenAnswerDialog}
            handleOpenCancelDialog={this.handleOpenCancelDialog}
            handleOpenViewDialog={this.handleOpenViewDialog}
            handleClose={this.handleCloseSecondGridDialog}
          />
        }
        {
          this.state.cancelDialogIsOpen &&
          <CancelDialog
            open={this.state.cancelDialogIsOpen}
            code={this.state.currentRequestWorkflow[0].code}
            handleCloseDialog={this.handleCloseCancelDialog}
            handleAccept={this.handleCancelRequest}
          />
        }
        {
          this.state.answerDialogIsOpen && !loadingRows && !exportingGrid &&
          <ReplyDialog
            open={this.state.answerDialogIsOpen}
            handleCloseDialog={this.handleCloseAnswerDialog}
            handleAccept={this.handleAnswerRequest}
            title={getLocalizedString("answerChangeTitle")}
            vacationCalculationData={vacationCalculationData}
            editData={this.state.currentRequestWorkflow[0]}
          />
        }
        {
          //modal abm
          this.state.typeToOpen !== "" && this.shouldOpenRequestDialog(this.state.typeToOpen) && !loadingRows && !exportingGrid && !this.state.isReadOnly &&
          <RequestDialog
            startHolidaysOnNonWorkingDay={startHolidaysOnNonWorkingDay}
            allowHalfDayHolidays={allowHalfDayHolidays}
            usedDates={usedDates}
            vacationCalculationData={vacationCalculationData}
            permissionCalculationData={permissionCalculationData}
            advanceCalculationData={advanceCalculationData}
            typesPermission={typesPermission}
            typesAdvances={typesAdvances}
            open={this.state.typeToOpen !== ""}
            handleClose={this.handleCloseRequest}
            handleRequest={this.handleCreateRequest}
            companyColor={color}
            requestType={this.state.typeToOpen}
            recipients={recipients}
            handleNoRecipient={this.handleNoRecipient}
            handleViewBalance={this.handleOpenViewBalance}
            hideViewAccountability={this.state.comesFromElsewhere}
          />
        }
        {
          //modal para mirar
          this.state.typeToOpen !== "" && !loadingRows && !exportingGrid && this.state.isReadOnly &&
          <RequestDialog
            usedDates={usedDates}
            vacationCalculationData={vacationCalculationData}
            permissionCalculationData={permissionCalculationData}
            advanceCalculationData={advanceCalculationData}
            typesPermission={typesPermission}
            typesAdvances={typesAdvances}
            open={this.state.typeToOpen !== ""}
            handleClose={this.handleCloseRequest}
            handleRequest={this.handleCreateRequest}
            companyColor={color}
            requestType={this.state.typeToOpen}
            isReadOnly={this.state.isReadOnly}
            handleCloseViewDialog={this.handleCloseViewDialog}
            editData={this.state.currentRequestWorkflow[0]}
            recipients={recipients}
            handleNoRecipient={this.handleNoRecipient}
            handleViewBalance={this.handleOpenViewBalance}
          />
        }
        {
          // ver saldos
          this.state.viewBalanceIsOpen && !loadingRows && !exportingGrid &&
          <ViewBalanceDialog
            open={this.state.viewBalanceIsOpen}
            data={ccHoursAppeals}
            dateFormat={dateFormat}
            locale={locale}
            handleClose={this.handleCloseViewBalance}
          />
        }
      </CommonPage>
    );
  }

  handleCloseViewDialog = () => {
    this.setState({ isReadOnly: false })
  }

  handleOpenViewDialog = (data) => {
    this.setState({ isReadOnly: true, typeToOpen: data[0].type })
  }

  handleAnswerRequest = (comment, status) => {
    let data;

    //Si acepta la propuesta, pasa al estado 4
    if (status) {
      data = {
        "code": this.state.currentRequestWorkflow[0].code,
        "id_status": "4",
        "comments": comment,
      }
    } else {
      //Si la rechaza, va al estado 5
      data = {
        "code": this.state.currentRequestWorkflow[0].code,
        "id_status": "5",
        "comments": comment,
      }
    }

    this.props.dispatch(changeRequestStatus(data, this.state.currentRequestWorkflow[0].type, false, false)).then((resp) => {
      this.handleCloseAnswerDialog(resp)
    }).then(resp => this.handleCloseSecondGridDialog());
  }

  handleOpenAnswerDialog = () => {
    this.setState({ answerDialogIsOpen: true });
  }

  handleCloseAnswerDialog = (shouldReloadTable) => {
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      this.setState({ answerDialogIsOpen: false },
        () => {
          this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
        }
      );
    } else {
      this.setState({ answerDialogIsOpen: false });
    }
  }

  handleOpenCancelDialog = () => {
    this.setState({ cancelDialogIsOpen: true });
  }

  handleCloseCancelDialog = () => {
    this.setState({ cancelDialogIsOpen: false });
    this.handleCloseSecondGridDialog();
  }

  handleCancelRequest = (request) => {
    this.props.dispatch(changeRequestStatus(request, this.state.currentRequestWorkflow[0].type, true, true));
  }

  handleCreateRequest = (request) => {
    this.props.dispatch(setColaboratorAppeal(request, this.state.includesHistory, this.state.typeToOpen));
  }

  handleSwitchClick = () => {
    this.setState({ includesHistory: !this.state.includesHistory },
      () => {
        this.props.dispatch(getMyRequestsRows(this.state.includesHistory));
      }
    );
  };

  handleRowClick = row => {
    if (row && row.data !== undefined) {
      this.setState({ currentRequestWorkflow: row.data.complete_workflow, secondGridDialogIsOpen: true });
    }
  };

  handleCloseSecondGridDialog = () => {
    this.setState({ secondGridDialogIsOpen: false, typeToOpen: "" });
  }
}

export default connect(
  createStructuredSelector({
    usedDates: getUsedDates,
    recipients: getRecipients,
    myRequestsRows: getMyRequests,
    dateFormat: getDateFormat,
    locale: getLocale,
    vacationCalculationData: getVacationCalculationData,
    permissionCalculationData: getPermissionCalculationData,
    advanceCalculationData: getAdvanceCalculationData,
    ccHoursAppeals: getCCHoursAppeals,

    typesPermission: getTypesPermission,
    typesAdvances: getTypesAdvances,
    decimalSeparator: getDecimalSeparator,

    exportingGrid: getIsExportingGrid,
    loadingRows: loading,
    color: getAppColor,

    userData: getUserData,
    loginResponse: getLoginResponse,

    vacationsEnabled: getVacationsEnabled,
    permissionsEnabled: getPermissionsEnabled,
    loansEnabled: getLoansEnabled,
    startHolidaysOnNonWorkingDay: getStartHolidaysOnNonWorkingDay,
    allowHalfDayHolidays: getAllowHalfDayHolidays,
  })
)(MyRequests);
