

import * as actionTypes from "./actionTypes";
import { UPDATE_AVATAR } from "src/app/actionTypes";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import {
    getMyPersonalDataAPI,
    updateMyPersonalDataAPI,
    getProvinces,
    getCounties,
    getRegions,
    uploadProfileImageAPI,
    getUserLocationDataAPI,
} from "@icarius-connection/api";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getOpinion } from "@icarius-utils/like";

export const getMyPersonalDataAction = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_PERSONAL_DATA });
    try {
        let response = await getMyPersonalDataAPI();

        let data = response.data && response.data.data;
        let editableFields = response.data && response.data.editable_fields;
        let dateFormat = response.data && response.data.date_format;
        let enableCertificates = response.data && response.data.enableCertificates;
        let enableReceipts = response.data && response.data.enableReceipts;
        let sectionsToHide = response.data && response.data.sectionsToHide;
        let fieldsToHide = response.data && response.data.fieldsToHide;
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.GET_PERSONAL_DATA_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.GET_PERSONAL_DATA_FULFILLED,
            payload: {
                data: {
                    ...data,
                    OPINION: getOpinion(response.data.data),
                },
                editableFields,
                dateFormat,
                enableCertificates,
                enableReceipts,
                sectionsToHide,
                fieldsToHide,
            }
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_PERSONAL_DATA_REJECTED });
    }
};

export const updateMyPersonalDataAction = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.MODIFY_PERSONAL_DATA });
    try {
        let response = await updateMyPersonalDataAPI(data);
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.MODIFY_PERSONAL_DATA_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.MODIFY_PERSONAL_DATA_FULFILLED,
            payload: data,
        });

        dispatch(openSnackbarAction({ msg: getLocalizedString("correctlyModifiedPersonalData"), severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.MODIFY_PERSONAL_DATA_REJECTED });
    }
};

export const uploadProfileImageAction = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.MODIFY_MY_PHOTO });
    try {
        let response = await uploadProfileImageAPI({ image: data });
        let avatar = response.data && response.data.url;
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.MODIFY_MY_PHOTO_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.MODIFY_MY_PHOTO_FULFILLED,
            payload: avatar,
        });

        dispatch({
            type: UPDATE_AVATAR,
            payload: { avatar }
        });

        dispatch(openSnackbarAction({ msg: getLocalizedString("correctlyModifiedPhoto"), severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.MODIFY_MY_PHOTO_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getRegionByCountryAction = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_REGIONS_BY_COUNTRY });
    try {
        let query = `?id=${code}`;
        let response = await getRegions(query);
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.GET_REGIONS_BY_COUNTRY_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.GET_REGIONS_BY_COUNTRY_FULFILLED,
            payload: response.data.result,
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_REGIONS_BY_COUNTRY_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getProvinceByRegionAction = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_PROVINCES_BY_REGION });
    try {
        let query = `?id=${code}`;
        let response = await getProvinces(query);
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.GET_PROVINCES_BY_REGION_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.GET_PROVINCES_BY_REGION_FULFILLED,
            payload: response.data.result,
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_PROVINCES_BY_REGION_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getCountiesByProvinceAction = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_COUNTIES_BY_PROVINCE });
    try {
        let query = `?id=${code}`;
        let response = await getCounties(query);
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.GET_COUNTIES_BY_PROVINCE_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.GET_COUNTIES_BY_PROVINCE_FULFILLED,
            payload: response.data.result,
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_COUNTIES_BY_PROVINCE_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getUserLocationDataAction = (employeeCode) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_LOCATION });
    try {
        let response = await getUserLocationDataAPI(`?user=${employeeCode}`);

        let counties = response.data && response.data.result.counties;
        let provinces = response.data && response.data.result.provinces;
        let regions = response.data && response.data.result.regions;
        let status = response.data && response.data.status;

        if (status !== "OK") {
            dispatch({ type: actionTypes.GET_LOCATION_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.GET_LOCATION_FULFILLED,
            payload: {
                counties,
                provinces,
                regions,
            },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_LOCATION_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};