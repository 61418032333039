import { useState, useEffect } from 'react';
import { formatDate } from '@mobiscroll/react';
import { useDispatch } from 'react-redux';
import { calendarTypeColors } from '@icarius-utils/colors';
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useGenericSchedule = (schedules, initialShifts, showShortTitle) => {

  const [isEdit, setEdit] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [tempShift, setTempShift] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [headerText, setHeader] = useState('');
  const [title, setTitle] = useState('');
  const [shortTitle, setShortTitle] = useState('');
  const [shiftDate, setDates] = useState([]);

  const dispatch = useDispatch();

  //setear los iniciales, setear el color y el tooltip
  useEffect(() => {
    setShifts(() => initialShifts.map(item => ({ ...item, color: calendarTypeColors[item.type], tooltip: item.title })));
  }, [initialShifts])

  // cambiar el tooltip a usar segun el tipo de title
  useEffect(() => {
    setShifts((prev) => prev.map(item => ({ ...item, tooltip: showShortTitle ? (item.shortTitle || item.title) : item.title })));
  }, [showShortTitle])

  const renderLabelContent = (args) => {
    const { original } = args;
    return showShortTitle ? (original.shortTitle || original.title) : original.title;
  }

  const getNewEventToSave = () => {
    const start = new Date(shiftDate[0]);
    const end = new Date(shiftDate[1]);

    return {
      id: tempShift.id,
      start: start,
      end: end,
      resource: tempShift.resource,
      type: 'request',
      color: calendarTypeColors.request,
      scheduleId: schedule,
      title: title,
      shortTitle: shortTitle,
      tooltip: showShortTitle ? shortTitle : title,
    };
  }

  const saveEvent = () => {
    if (!schedule) return;

    const newEvent = getNewEventToSave();

    if (isEdit) {
      // update the event in the list
      const index = shifts.findIndex(x => x.id === tempShift.id);;
      const newEventList = [...shifts];

      newEventList.splice(index, 1, newEvent);
      setShifts(newEventList);
    } else {
      // add the new event to the list
      setShifts([...shifts, newEvent]);
    }
    // close the popup
    setPopUpIsOpen(false);
  };

  const onEventClick = (args) => {
    const event = args.event;

    if (event.type === 'holiday' || event.type === 'absence') return;

    //si intento editar un dia libre o schedule, y tengo para el mismo dia un exception, impedir editar
    if (event.type === 'free' || event.type === 'scheduled' || event.type === 'exception') {
      const alreadyHasRequest = shifts.some(shift => {
        return (
          shift.type === 'request' &&
          shift.resource === args.event.resource &&
          (
            new Date(shift.start).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0) ||
            new Date(shift.end).setHours(0, 0, 0, 0) === new Date(args.event.start).setHours(0, 0, 0, 0)
          )
        );
      })

      if (alreadyHasRequest) {
        dispatch(openSnackbarAction({ msg: 'Ya existe una solicitud para este dia', severity: "warning" }));
        return;
      }
    }

    const title = 'Solicitud de horario';

    if (formatDate('DD/MM/YYYY', new Date(event.start)) === formatDate('DD/MM/YYYY', new Date(event.end))) {
      setHeader(
        `<div>${title} </div><div class="employee-shifts-day"> 
        ${formatDate('DDDD', new Date(event.start))}, ${formatDate('DD MMMM YYYY', new Date(event.start))}</div>`
      );
    } else {
      setHeader(
        `<div>${title} </div><div class="employee-shifts-day"> 
        ${formatDate('DDDD', new Date(event.start))}, ${formatDate('DD MMMM YYYY', new Date(event.start))} al 
        ${formatDate('DDDD', new Date(event.end))}, ${formatDate('DD MMMM YYYY', new Date(event.end))}</div>`
      );
    }

    setEdit(true);
    setTempShift({ ...event });
    loadPopupForm(event);
    setPopUpIsOpen(true);
  };

  const onClose = () => {
    if (!isEdit) {
      // refresh the list, if add popup was canceled, to remove the temporary event
      setShifts([...shifts]);
    }
    setPopUpIsOpen(false);
  };

  // cuando se abre el modal
  const loadPopupForm = (event) => {
    setTitle(event.title);
    setShortTitle(event.shortTitle);
    setDates([event.start, event.end]);
    setSchedule(event.scheduleId);
  };

  const getPopupButtons = () => {
    return [
      'cancel',
      {
        handler: () => {
          saveEvent();
        },
        keyCode: 'enter',
        text: 'Guardar',
        cssClass: 'mbsc-popup-button-primary'
      }
    ];
  };

  const handleDateChange = (args) => {
    const schedule = schedules.find(item => item.key === args.value);
    const [scheduleStartHours, scheduleStartMinutes] = schedule.start.split(':');
    const [scheduleEndHours, scheduleEndMinutes] = schedule.end.split(':');

    const newStart = new Date(shiftDate[0]);
    newStart.setHours(scheduleStartHours, scheduleStartMinutes, 0, 0);

    const newEnd = new Date(shiftDate[0]);
    newEnd.setHours(scheduleEndHours, scheduleEndMinutes, 0, 0);

    const scheduleHasNoTime = schedule.start === "00:00" && schedule.end === "00:00";

    // si el end del schedule es menor al start, es porque termina al otro dia
    if (!scheduleHasNoTime && newEnd <= newStart) {
      newEnd.setDate(newEnd.getDate() + 1);
    }

    setDates([newStart, newEnd]);
    setTitle(args.valueText);
    setShortTitle(schedule?.shortTitle || '');
    setSchedule(args.value);
  };

  const scheduleFunctions = {
    onEventClick,
    getPopupButtons,
    onClose,
    handleDateChange,
    renderLabelContent,
  };

  const scheduleData = {
    shifts,
    schedule,
    popUpIsOpen,
    isEdit,
    headerText,
    shiftDate,
  };

  return {
    scheduleFunctions,
    scheduleData,
  }
}

export default useGenericSchedule;