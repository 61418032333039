import React from "react";
import { Grid, DialogTitle, Dialog, DialogContent, Button } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import paths from "@icarius-localization/paths";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "85%",
  },
})(Dialog);

const SecondGridDialogIsOpen = (props) => {

  const {
    open,
    data,
    dateFormat,
    locale,
    handleOpenAnswerDialog,
    handleOpenCancelDialog,
    handleOpenViewDialog,
    handleClose,
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`${getLocalizedString("requestHistory")} - ${data[0].code}`}
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justify="flex-end" alignItems="center" style={{ paddingBottom: 10 }}>
          <Button style={{ marginBottom: 5 }} className="whiteText" disableRipple={true} variant={"outlined"} onClick={() => handleOpenViewDialog(data)}>
            {getLocalizedString("viewButton")}
          </Button>
          {
            data[0].id_status === "1" &&
            <Button style={{ marginLeft: 7, marginBottom: 5 }} className="whiteText" disableRipple={true} onClick={handleOpenCancelDialog} variant={"outlined"}>
              {getLocalizedString("cancelRequest")}
            </Button>
          }
          {
            data[0].id_status === "3" &&
            <Button style={{ marginLeft: 7, marginBottom: 5 }} className="whiteText" disableRipple={true} onClick={handleOpenAnswerDialog} variant={"outlined"}>
              {getLocalizedString("answerPropose")}
            </Button>
          }
        </Grid>
        <CommonPage
          gridTitle={`${getLocalizedString("requestHistory")} - ${data[0].code}`}
          columnDefPage={paths.myRequestsSecondGrid}
          rowData={data}
          locale={locale}
          dateFormat={dateFormat}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default SecondGridDialogIsOpen;
