import React from "react";
import {
  Typography,
  DialogContent,
  DialogActions, MenuItem, Grid 
} from "@material-ui/core";
import { TextField, Select, DatePicker } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateIsAfterToday, createDateFromDDMMYYYY, formatDate, getTomorrow } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import createDecorator from 'final-form-calculate'
import NumberFormatCustom from './numberFormatCustom';
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";

class NewPayRiseContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userSelectionDialogIsOpen: false,
    }
  }

  componentDidMount() {
    if(!this.props.isReadOnly && !this.props.isForEdit && this.props.recipients.fire.length === 0){
      this.props.handleNoRecipient();
      this.handleClose();
    }
  }

  handleClose = (shouldReloadTable) => {
    if (this.props.isForEdit && shouldReloadTable) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  textIsValid = values => {
    return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 1024;
  };

  dataIsComplete = (values, checkAmount = true) => {
    if (this.props.isForEdit) {
      if (dateIsAfterToday(values.date) && this.textIsValid(values) && values.legalType)
        return true;
      return false;
    }

    if (!this.props.isForEdit && !this.props.isReadOnly && !values.receptor) return false;

    if (checkAmount) {
      if (values.legalType && values.colaborator && this.isAmountInRange(values) && this.textIsValid(values) && dateIsAfterToday(values.date))
        return true;
    } else {
      if (values.legalType && values.colaborator && this.textIsValid(values) && dateIsAfterToday(values.date)){
        return true;
      }
    }

    return false;
  };

  validateAndCreate = values => {
    if (this.dataIsComplete(values)) {
      const request = {
        code: this.props.requestCode,        
        colaborator_id: values.colaborator,
        value: values.amount.toString(),
        date: formatDate(values.date),
        reason_id: values.legalType,
        comments: values.descripcion,
        receptor: values.receptor,
      }

      if (this.props.isForEdit) {
        delete request.receptor;
        delete request.colaborator_id;
        delete request.value;
      } else {
        delete request.code;
      }

      this.handleRequest(request);
    }
    else {
      const colaborator = this.props.colaborators.find(colab => colab.code === values.colaborator);

      if (this.dataIsComplete(values, false) && values.amount && values.amount && values.amount <= colaborator.salary) {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("newSalaryMustBeGreaterThanPreviousSalary"), severity: "error" }));
      } else {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
      }
    }
  };

  handleRequest = request => {
    this.props.handleRequest(request);
    if (!this.props.isForEdit) {
      this.handleClose(true);
    }
  };

  isAmountInRange = (values) => {
    const colaborator = this.props.colaborators.find(colab => colab.code === values.colaborator);
    if (colaborator) {
      return values.amount && values.amount > 0 && values.amount > colaborator.salary;
    }
    return false;
  }

  render() {
    const { raiseCalculationData, colaborators, editData, isForEdit, isReadOnly, recipients } = this.props;

    let initialValues;

    const recipientInitialValue = recipients.raise.length === 1 ? recipients.raise[0].code : "";

    if (!isForEdit && !isReadOnly) {
      initialValues = {
        date: "",
        legalType: "",
        descripcion: "",
        amount: "",
        receptor: recipientInitialValue, //si tiene un valor solo, inicializar con ese
      };
    } else {
      initialValues = {
        date: createDateFromDDMMYYYY(editData.expected_date),
        descripcion: editData.comments,
        amount: editData.value.toString(),
        legalType: editData.reason_id,
        colaborator: editData.colaborator_name,
        actualSalary: editData.current_salary,
        actualSalaryCurrency: editData.currency,
      };
    } 

    const calculator = createDecorator(
      {
        field: 'colaborator',
        updates: {
          actualSalary: (colaboratorValue) => {
            if (isForEdit) return editData.current_salary;

            if(isReadOnly){
              const colaborator = this.props.colaborators.find(colab => colab.value === colaboratorValue);
              if (colaborator) {            
                return colaborator.salary;
              }
              return null;
            }

            const colaborator = this.props.colaborators.find(colab => colab.code === colaboratorValue);
            if (colaborator) {              
              return colaborator.salary;
            }
            return null;
          },
          actualSalaryCurrency: (colaboratorValue) => {
            if (isForEdit) return editData.currency;

            if(isReadOnly){
              const colaborator = this.props.colaborators.find(colab => colab.value === colaboratorValue);
              if (colaborator) {       
                return colaborator.currency;
              }
              return null;
            }

            const colaborator = this.props.colaborators.find(colab => colab.code === colaboratorValue);
            if (colaborator) {
              return colaborator.currency;
            }
            return null;
          }
        },
      },
    );

    return (
      <Form
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initialValues}
        decorators={[calculator]}
        onSubmit={this.validateAndCreate}
        render={({ handleSubmit, form, values }) => {
          window.setFormValue = form.mutators.setValue;
          return (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent style={{ paddingTop: 0 }}>              
              <Grid container direction="column" justify="center">

                {
                  !isForEdit && !isReadOnly &&
                  <Grid container direction="column" justify="center" item>                    
                    <Select
                      disabled={isForEdit || isReadOnly}
                      label={getLocalizedString("requestReceptor")}
                      name={"receptor"}
                      required
                      formControlProps={{
                        margin: "normal",
                      }}>
                      {
                        recipients.raise.map((subItem) => (
                        <MenuItem
                          className={"whiteText"}
                          key={subItem.code}
                          value={subItem.code}>
                          {subItem.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                }                

                {
                  !isForEdit && !isReadOnly &&
                  <Grid container direction="column" justify="center" item>
                    <Select
                      onOpen={() => this.setState({ userSelectionDialogIsOpen: true })}
                      open={false}
                      required
                      disabled={isForEdit}
                      label={getLocalizedString("payRiseRequestedFor")}
                      name={"colaborator"}
                      formControlProps={{
                        margin: "normal",
                      }}>
                      {
                        colaborators.map((subItem, index) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.code}
                            value={subItem.code}>
                            {subItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                }

                {
                  (isForEdit || isReadOnly) &&
                  <Grid container direction="row" justify="center" item style={{ marginTop: 16 }}>    
                    <TextField 
                      required={!isReadOnly}
                      label={getLocalizedString("payRiseRequestedFor")}
                      disabled 
                      margin={"none"} 
                      name="colaborator" 
                      />
                  </Grid>
                }

                <Grid container direction="row" item>
                  <Grid container direction="column" item xs={12} sm={6} style={{ marginTop: 16 }}>
                    <TextField
                      disabled
                      label={getLocalizedString("actualSalary")}
                      name={"actualSalary"}
                      margin={"none"}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 1024);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom, min: "1", step: "0.1"
                      }}
                    />
                  </Grid>

                  <Grid container direction="column" item className="secondFieldPadding" xs={12} sm={6} style={{ marginTop: 16 }}>
                    <TextField
                      label={getLocalizedString("salaryCoin")}
                      disabled
                      name={"actualSalaryCurrency"}
                      margin={"none"}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 1024);
                      }}                            
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" item style={{ marginTop: 5 }}>
                  <Grid container direction="column" item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        required={!isReadOnly}
                        label={getLocalizedString("payRiseExpectedDate")}
                        variant="inline"
                        disablePast
                        minDate={getTomorrow()}
                        format="dd/MM/yyyy"
                        margin="normal"
                        name="date"
                        disabled={isReadOnly || values.colaborator === "" || values.colaborator === null || values.colaborator === undefined}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid container direction="row" item className="secondFieldPadding" xs={12} sm={6} style={{marginTop: 16}}>
                    <TextField
                      required={!isReadOnly}
                      label={getLocalizedString("newSalaryValue")}
                      name={"amount"}
                      margin={"none"}
                      disabled={(isForEdit || isReadOnly) ? true : (values.colaborator === "" || values.colaborator === null || values.colaborator === undefined)}
                      InputProps={{
                        inputComponent: NumberFormatCustom, min: "1", step: "0.1"
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 1024);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column" justify="center" item style={{marginTop: 16}}>
                  <Select
                    disabled={isReadOnly ? true : isForEdit ? false : (values.colaborator === "" || values.colaborator === null || values.colaborator === undefined)}
                    label={getLocalizedString("reasonToRegisterInDigitalFolder")}
                    required={!isReadOnly}
                    name={"legalType"}
                    formControlProps={{
                      margin: "normal",
                    }}>
                    {
                      raiseCalculationData.reasons.map((subItem, index) => (
                        <MenuItem
                          className={"whiteText"}
                          key={subItem.code}
                          value={subItem.code}>
                          {subItem.value}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                <Grid
                  style={{ paddingTop: 16 }}
                  container
                  direction="row"
                  justify="flex-start"
                  item>
                  <TextField
                    label={"Detalle de la justificación del aumento solicitado"}
                    required={!isReadOnly}
                    name={"descripcion"}
                    margin={"none"}
                    disabled={isReadOnly ? true : isForEdit ? false : (values.colaborator === "" || values.colaborator === null || values.colaborator === undefined)}
                    onInput={e => {
                      e.target.value = e.target.value.toString().slice(0, 1024);
                    }}
                    multiline={true}
                    rows={1}
                    rowsMax={5}
                  />
                  {
                    !isReadOnly && 
                    <Typography
                      style={{ paddingTop: 5 }}
                      className={!this.textIsValid(values) ? "errorColor" : "whiteText"}
                      variant="caption"
                      gutterBottom>
                      {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                        }. ${getLocalizedString("maxCharRequests")}.`}
                    </Typography>
                  }
                </Grid>
              </Grid>
            </DialogContent>
            {
              isReadOnly &&
              <div style={{marginTop: 32}} />
            }
            {
              !isReadOnly && 
              <DialogActions>
                <ButtonDialogAction
                  onClick={this.handleClose}
                  text={getLocalizedString("disagree")}
                />
                <ButtonDialogAction
                  type="submit"
                  isAccept
                  text={getLocalizedString("agree")}
                />
              </DialogActions>
            }
            {
              this.state.userSelectionDialogIsOpen &&
              <UserSelectionDialog
                open={this.state.userSelectionDialogIsOpen}
                initialData={[values.colaborator]}
                users={colaborators.map((colab) => {
                  return {
                    FOTOGRAFIA: colab["FOTOGRAFIA"],
                    name: colab.value,
                    key: colab.code,
                  }
                })}
                title={getLocalizedString("selectColabRise")}
                selectedUsersTitle={getLocalizedString("selectedColab")}
                handleSubmit={(value) => window.setFormValue("colaborator", value[0])}
                handleClose={() => this.setState({ userSelectionDialogIsOpen: false })}
                singleSelection
              />
            }
          </form>
        )}}
      />
    );
  }
}
export default NewPayRiseContent;
