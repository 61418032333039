import NAME from "./constants";

export const LIKE_REQUEST = NAME + "/LIKE_REQUEST";
export const LIKE_REQUEST_END = NAME + "/LIKE_REQUEST_END";

export const GET_MY_REQUESTS = NAME + "/GET_MY_REQUESTS";
export const GET_MY_REQUESTS_FULFILLED = NAME + "/GET_MY_REQUESTS_FULFILLED";
export const GET_MY_REQUESTS_REJECTED = NAME + "/GET_MY_REQUESTS_REJECTED";

export const SET_COLABORATOR_APPEAL = NAME + "/SET_COLABORATOR_APPEAL";
export const SET_COLABORATOR_APPEAL_FULFILLED = NAME + "/SET_COLABORATOR_APPEAL_FULFILLED";
export const SET_COLABORATOR_APPEAL_REJECTED = NAME + "/SET_COLABORATOR_APPEAL_REJECTED";

export const MODIFY_REQUEST_STATUS = NAME + "/MODIFY_REQUEST_STATUS";
export const MODIFY_REQUEST_STATUS_FULFILLED = NAME + "/MODIFY_REQUEST_STATUS_FULFILLED";
export const MODIFY_REQUEST_STATUS_REJECTED = NAME + "/MODIFY_REQUEST_STATUS_REJECTED";