import {
  GET_MY_REQUESTS,
  GET_MY_REQUESTS_FULFILLED,
  GET_MY_REQUESTS_REJECTED,
  SET_COLABORATOR_APPEAL,
  SET_COLABORATOR_APPEAL_FULFILLED,
  SET_COLABORATOR_APPEAL_REJECTED,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  MODIFY_REQUEST_STATUS,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getMyRequestsAPI,
  setColaboratorAppealAPI,
  setColaboratorAppealPermissionAPI,
  setColaboratorAppealAdvanceAPI,
  modifyVacationAppealStatusAPI,
  modifyPermissionAppealStatusAPI,
  modifyAdvanceAppealStatusAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { requestsIds, getRequestSnackbarText } from "@icarius-utils/requestsIds";

export const getMyRequestsRows = (includesHistory) => async (dispatch) => {
  dispatch({ type: GET_MY_REQUESTS });
  try {
    let response = await getMyRequestsAPI(`?include_historicals=${includesHistory}`);

    let recipients = response.data.recipients;
    let myRequests = response.data.result;
    let vacationCalculationData = response.data.vacations_calculation_data;
    let permissionCalculationData = response.data.permission_calculation_data;
    let advanceCalculationData = response.data.advances_calculation_data;
    let typesPermission = response.data.types_permissions;
    let typesAdvances = response.data.types_advances;
    let decimalSeparator = response.data.decimal_separator;
    let currencyLocalization = response.data.currency_localization;
    let dateFormat = response.data.date_format;
    let usedDates = response.data.used_dates;
    let ccHoursAppeals = response.data.ccHoursAppeals;
    let vacationsEnabled = response.data.vacationsEnabled;
    let permissionsEnabled = response.data.permissionsEnabled;
    let loansEnabled = response.data.loansEnabled;
    let startHolidaysOnNonWorkingDay = response.data.startHolidaysOnNonWorkingDay;
    let allowHalfDayHolidays = response.data.allowHalfDayHolidays;

    dispatch({
      type: GET_MY_REQUESTS_FULFILLED,
      payload: {
        recipients: recipients,
        myRequests: myRequests,
        vacationCalculationData: vacationCalculationData,
        permissionCalculationData: permissionCalculationData,
        advanceCalculationData: advanceCalculationData,
        typesPermission: typesPermission,
        typesAdvances: typesAdvances,
        decimalSeparator: decimalSeparator,
        currencyLocalization: currencyLocalization,
        dateFormat: dateFormat,
        usedDates: usedDates,
        ccHoursAppeals: ccHoursAppeals,
        vacationsEnabled: vacationsEnabled,
        permissionsEnabled: permissionsEnabled,
        loansEnabled: loansEnabled,
        startHolidaysOnNonWorkingDay: startHolidaysOnNonWorkingDay,
        allowHalfDayHolidays: allowHalfDayHolidays,
      },
    });

    if (response.data.result === false) {
      dispatch({ type: GET_MY_REQUESTS_REJECTED });
      return response;
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_MY_REQUESTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const setColaboratorAppeal = (request, includesHistory, type) => async (dispatch) => {
  dispatch({ type: SET_COLABORATOR_APPEAL });
  try {
    let response;
    let usedDates = null;

    switch (type) {
      case requestsIds.id_type.VAC:
        response = await setColaboratorAppealAPI(request);
        usedDates = response.data.used_dates;
        break;
      case requestsIds.id_type.PERM:
        response = await setColaboratorAppealPermissionAPI(request);
        usedDates = response.data.used_dates;
        break;
      case requestsIds.id_type.ANT:
        response = await setColaboratorAppealAdvanceAPI(request);
        break;

      default: break;
    }

    let status = response.data && response.data.status;

    if (status === "OK") {
      let payloadData = {
        data: response.data.result[0],
        includesHistory: includesHistory,
        type: type,
        usedDates: usedDates,
      }

      if (usedDates === null) {
        delete payloadData.usedDates;
      }

      dispatch({
        type: SET_COLABORATOR_APPEAL_FULFILLED,
        payload: payloadData,
      });

      dispatch(openSnackbarAction({ msg: getRequestSnackbarText("1"), severity: "success" }));

      return response;
    } else {
      let msg;
      let duration = 5000;
      switch (response.data.status) {
        case "ERROR_NO_DATE_AVAILABLE":
          msg = response.data.message;
          duration = null;
          break;
        case "VALIDATION_ERROR":
          msg = getLocalizedErrorString("validationErrorRequests");
          break;
        case "ERROR_DAYS_QUANTITY":
          msg = getLocalizedErrorString("errorDaysQuantityRequests");
          break;
        case "ERROR_FORMAT_DATES":
          msg = getLocalizedErrorString("formatDateErrorRequests");
          break;
        case "ERROR_DATES":
          msg = getLocalizedErrorString("dateErrorRequests");
          break;
        case "ERROR_OVERLAP":
          msg = getLocalizedErrorString("overlapErrorRequests");
          break;
        case "ERROR_EXISTS":
          msg = 'Tiene una solicitud con documentación pendiente de aprobación en tramite';
          break;
        default:
          msg = getLocalizedErrorString("defaultException");
      }
      dispatch({ type: SET_COLABORATOR_APPEAL_REJECTED });
      dispatch(openSnackbarAction({ msg: msg, severity: "error", duration }));
      return;
    }
  } catch (e) {
    dispatch({ type: SET_COLABORATOR_APPEAL_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const changeRequestStatus = (data, type, canAppeal = false, shouldRemove = false) => async (dispatch) => {
  dispatch({ type: MODIFY_REQUEST_STATUS });
  try {

    let response;
    let usedDates = null;

    switch (type) {
      case requestsIds.id_type.VAC:
        response = await modifyVacationAppealStatusAPI(data);
        usedDates = response.data.used_dates;
        break;
      case requestsIds.id_type.PERM:
        response = await modifyPermissionAppealStatusAPI(data);
        usedDates = response.data.used_dates;
        break;
      case requestsIds.id_type.ANT:
        response = await modifyAdvanceAppealStatusAPI(data);
        break;
      default: break;
    }


    if (response.data.status === "OK") {
      const payloadData = {
        data: data,
        shouldRemoveFromArray: shouldRemove,
        canUserAppeal: canAppeal,
        type: type,
        usedDates: usedDates,
      }

      if (usedDates === null) {
        delete payloadData.usedDates;
      }

      dispatch(openSnackbarAction({ msg: getRequestSnackbarText(data.id_status), severity: "success" }));

      dispatch({
        type: MODIFY_REQUEST_STATUS_FULFILLED,
        payload: payloadData,
      });

    } else {
      let msg;
      switch (response.data.status) {
        case "VALIDATION_ERROR":
          msg = getLocalizedErrorString("validationErrorRequests");
          break;
        case "ERROR_DAYS_QUANTITY":
          msg = getLocalizedErrorString("errorDaysQuantityRequests");
          break;
        case "ERROR_FORMAT_DATES":
          msg = getLocalizedErrorString("formatDateErrorRequests");
          break;
        case "ERROR_DATES":
          msg = getLocalizedErrorString("dateErrorRequests");
          break;
        case "ERROR_OVERLAP":
          msg = getLocalizedErrorString("overlapErrorRequests");
          break;
        default:
          msg = getLocalizedErrorString("defaultException");
      }
      dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
      return;
    }

    return response;
  } catch (e) {
    dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};