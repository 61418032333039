import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "colaborator_code",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("nameAndSurname"),
            field: "colaborator_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("sentTo"),
            field: "employer_name",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        },
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("reason"),
            field: "reason",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        },
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("legalReason"),
            field: "legal_reason",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        },
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("expectedDate"),
            field: "expected_date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            enableRowGroup: true,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("newProposedSalary"),
            field: "value",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.myRequestsManager],
        config: {
            headerName: getLocalizedString("comments"),
            field: "comments",
            filter: "agTextColumnFilter",
        }
    },
]