import NAME from "./constants";

export const getHelp = store => store[NAME].helpDocumentation;

export const loading = store =>
  store[NAME].exporting || store[NAME].gettingMyRequests || store[NAME].settingRequest;

export const getRecipients = store => store[NAME].recipients;
export const getMyRequests = store => store[NAME].myRequests;
export const getVacationCalculationData = store => store[NAME].vacationCalculationData;
export const getPermissionCalculationData = store => store[NAME].permissionCalculationData;
export const getAdvanceCalculationData = store => store[NAME].advanceCalculationData;
export const getTypesPermission = store => store[NAME].typesPermission;
export const getTypesAdvances = store => store[NAME].typesAdvances;
export const getDecimalSeparator = store => store[NAME].decimalSeparator;
export const getLocale = store => store[NAME].currencyLocalization;
export const getDateFormat = store => store[NAME].dateFormat;
export const getUsedDates = store => store[NAME].usedDates;
export const getCCHoursAppeals = store => store[NAME].ccHoursAppeals;
export const getVacationsEnabled = store => store[NAME].vacationsEnabled;
export const getPermissionsEnabled = store => store[NAME].permissionsEnabled;
export const getLoansEnabled = store => store[NAME].loansEnabled;
export const getStartHolidaysOnNonWorkingDay = store => store[NAME].startHolidaysOnNonWorkingDay;
export const getAllowHalfDayHolidays = store => store[NAME].allowHalfDayHolidays;