import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getAppColor } from "src/app/selectors";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { CloseIcon, RemoveIcon, AddIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Slider,
  Grid,
  DialogActions,
} from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { uploadProfileImageAction } from "@icarius-pages/myPeople/actions";
import { uploadProfileImageAction as uploadMyProfileImageAction } from "../actions";

const WIDTH = 200;
const HEIGHT = 200;

const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const ChangeProfileImageDialog = (props) => {
  const {
    open,
    isPeopleMaster,
    isNewEmployee,
    profileImage,
    userCode,
    displayWarning,
    handleSubmit,
    handleClose,
  } = props;

  const editorRef = useRef();

  const [showDialog, setShowDialog] = useState(false);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  useEffect(() => {
    if (displayWarning) {
      dispatch(openDialogAction({
        msg: (
          <>
            Apreciado colaborador: si usted quiere cargar o cambiar su fotografía, le solicitamos que sea una fotografía personal y para uso corporativo.
            <br />
            Muchas gracias por su colaboración.
          </>
        ),
        title: getLocalizedString("atention"),
        onConfirm: () => setShowDialog(true),
        acceptOnly: true,
      }));
    } else {
      setShowDialog(true);
    }
  }, [dispatch, displayWarning])

  useEffect(() => {
    // presetear la imagen
    if (profileImage) {
      toDataUrl(IMAGES_ENDPOINT + profileImage, (myBase64) => {
        setImage(myBase64);
      });
    }
  }, [profileImage])

  const handleNewImage = async (e) => {
    setImage(e.target.files[0]);
  };

  const handleScale = (e, newValue) => {
    setScale(parseFloat(newValue));
  };

  const handleDrop = (acceptedFiles) => {
    setImage(acceptedFiles[0]);
  };

  const handleConfirm = () => {
    if (!Boolean(image)) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar una imagen', severity: "error" }));
      return;
    }

    try {
      let data = editorRef.current.getImageScaledToCanvas().toDataURL();

      if (isNewEmployee) {
        handleClose();
        handleSubmit(data);
      } else {
        try {
          handleClose();
          dispatch(isPeopleMaster ? uploadProfileImageAction(data, userCode) : uploadMyProfileImageAction(data));
        } catch (e) {
          dispatch(openSnackbarAction({ msg: e, severity: "error" })
          );
        }
      }
    } catch (e) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={(open && showDialog) || false}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("modifyMyPhoto")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container>
          <Grid container justify="center" alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Dropzone
                onDrop={handleDrop}
                multiple={false}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
                disableClick
              >
                <AvatarEditor
                  ref={editorRef}
                  scale={parseFloat(scale)}
                  width={WIDTH}
                  height={HEIGHT}
                  borderRadius={WIDTH / 2}
                  image={image}
                  className="editor-canvas"
                  id="avatar-editor"
                />
              </Dropzone>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center" spacing={2} style={{ marginTop: 5 }}>
            <Grid item xs={10} style={{ display: "inline-flex" }}>
              <RemoveIcon htmlColor={color} style={{ marginRight: 15 }} />
              <Slider
                onChange={handleScale}
                min={1}
                max={2}
                step={0.01}
                defaultValue={1}
                value={scale}
              />
              <AddIcon htmlColor={color} tyle={{ marginLeft: 15 }} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container style={{ padding: "5px 10px 20px 10px" }}>
        <Grid container justify="center" alignItems="center" item xs={12}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            name="newImage"
            type="file"
            onChange={handleNewImage}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant={"contained"}
              disableRipple={true}
              style={{ fontWeight: 500 }}
              className="whiteText"
              component={"span"}>
              {getLocalizedString("uploadNewPhoto")}
            </Button>
          </label>
        </Grid>
      </Grid>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleConfirm} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default ChangeProfileImageDialog;