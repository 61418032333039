import React from "react";
import {
  Typography,
  DialogContent,
  DialogActions, MenuItem, Grid 
} from "@material-ui/core";
import { TextField, Select, DatePicker } from "mui-rff";
import { Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getTomorrow, dateIsAfterToday, createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";

class NewDisengagementContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userSelectionDialogIsOpen: false,
    }
  }

  componentDidMount() {
    if(!this.props.isReadOnly && !this.props.isForEdit && this.props.recipients.fire.length === 0){
      this.props.handleNoRecipient();
      this.handleClose();
    }
  }

  handleClose = (shouldReloadTable) => {
    if (this.props.isForEdit && shouldReloadTable) {
      this.props.handleClose(true);
    } else {
      this.props.handleClose();
    }
  };

  textIsValid = values => {
    return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 1024;
  };

  dataIsComplete = values => {
    if(this.props.isForEdit){
      if(dateIsAfterToday(values.date) && values.reasonToRegisterInDigitalFolder && values.legalType && this.textIsValid(values)){
        return true;
      }
      return false;
    }

    if (!this.props.isForEdit && !this.props.isReadOnly && !values.receptor) return false;
    if (values.reasonToRegisterInDigitalFolder && values.colaborator && dateIsAfterToday(values.date) && this.textIsValid(values))
      return true;
    return false;
  };

  validateAndCreate = values => {
    if (this.dataIsComplete(values)) {
      const request = {
        code: this.props.requestCode,
        colaborator_id: values.colaborator,
        date: formatDate(values.date),
        reason_id: values.reasonToRegisterInDigitalFolder,
        legal_reason_id: values.legalType,
        comments: values.descripcion,     
        receptor: values.receptor,   
      }

      if(this.props.isForEdit){
        delete request.receptor;
        delete request.colaborator_id;
      }else{
        delete request.code;
        delete request.legal_reason_id;
      }

      this.handleRequest(request);
    }
    else {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("completeWithValidInformation"), severity: "error" }));
    }
  };

  handleRequest = request => {
    this.props.handleRequest(request);
    if (!this.props.isForEdit) {
      this.handleClose(true);
    }
  };

  render() {
    const { fireCalculationData, colaborators, editData, isForEdit, isReadOnly, recipients } = this.props;

    let initialValues;

    const recipientInitialValue = recipients.fire.length === 1 ? recipients.fire[0].code : "";

    if (!isForEdit && !isReadOnly) {
      initialValues = {
        date: "",
        type: "",
        descripcion: "",
        amount: "",
        receptor: recipientInitialValue, //si tiene un valor solo, inicializar con ese
      };
    } else {
      initialValues = {
        colaborator: editData.colaborator_name,        
        legalType: editData.legal_reason_id,        
        date: createDateFromDDMMYYYY(editData.expected_date),            
        reasonToRegisterInDigitalFolder: editData.reason_id,  
        descripcion: editData.comments,
      };
    }

    return (
      <>
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={initialValues}
          onSubmit={this.validateAndCreate}
          render={({ handleSubmit, form, values }) => {
          window.setFormValue = form.mutators.setValue;
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent style={{ paddingTop: 0 }}>             
                <Grid container direction="column" justify="center">
                  {
                    !isForEdit && !isReadOnly &&
                    <Grid container direction="column" justify="center" item>                    
                      <Select
                        disabled={isForEdit || isReadOnly}
                        label={getLocalizedString("requestReceptor")}
                        name={"receptor"}
                        required
                        formControlProps={{
                          margin: "normal",
                        }}>
                        {
                          recipients.fire.map((subItem) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.code}
                            value={subItem.code}>
                            {subItem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  }                  

                  {
                    !isForEdit && !isReadOnly &&
                    <Grid container direction="column" justify="center" item>                      
                      <Select
                        onOpen={() => this.setState({ userSelectionDialogIsOpen: true })}
                        open={false}
                        disabled={isForEdit}
                        label={getLocalizedString("disengagementRequestedFor")}
                        required
                        name={"colaborator"}                        
                        formControlProps={{
                          margin: "normal",
                        }}>
                        {
                          colaborators.map((subItem) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.code}
                            value={subItem.code}>
                            {subItem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  }

                  {
                    (isForEdit || isReadOnly) &&
                    <Grid container direction="column" justify="center" item style={{marginTop: 16}}>
                      <TextField 
                        disabled 
                        margin={"none"} 
                        name="colaborator" 
                        label={getLocalizedString("disengagementRequestedFor")}
                        required={!isReadOnly}
                        />
                    </Grid>
                  }

                  {
                    isForEdit && !isReadOnly && 
                    <Grid container direction="column" justify="center" item>
                      <Select
                        disabled={!isForEdit || isReadOnly}
                        required={!isReadOnly}
                        label={getLocalizedString("disengagementLegalType")}
                        name={"legalType"}
                        formControlProps={{
                          margin: "normal",
                        }}>
                        {
                          fireCalculationData.legal_reasons.map((subItem) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.code}
                            value={subItem.code}>
                            {subItem.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  }

                  <Grid container direction="row" item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        disabled={isReadOnly}
                        required={!isReadOnly}
                        label={getLocalizedString("expectedDateOfDisengagement")}
                        variant="inline"
                        disablePast
                        minDate={getTomorrow()}
                        format="dd/MM/yyyy"
                        margin="normal"
                        name="date"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid container direction="column" justify="center" item>
                    <Select
                      disabled={isForEdit || isReadOnly}
                      label={getLocalizedString("reasonToRegisterInDigitalFolder")}
                      required={!isReadOnly}
                      name={"reasonToRegisterInDigitalFolder"}
                      formControlProps={{
                        margin: "normal",
                      }}>
                      {
                        fireCalculationData.reasons.map((subItem, index) => (
                        <MenuItem
                          className={"whiteText"}
                          key={subItem.code}
                          value={subItem.code}>
                          {subItem.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid container direction="column" justify="flex-start" item style={{marginTop: 16}}>
                    <TextField
                      required={!isReadOnly}
                      label={getLocalizedString("detailDisengagement")}
                      disabled={isReadOnly}
                      id="fieldToFocus"
                      name={"descripcion"}
                      margin={"none"}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 1024);
                      }}
                      multiline={true}
                      rows={1}
                      rowsMax={5}                      
                    />
                    {
                      !isReadOnly && 
                      <Typography
                        style={{ paddingTop: 5 }}
                        className={!this.textIsValid(values) ? "errorColor" : "whiteText"}
                        variant="caption"
                        gutterBottom>
                        {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                          }. ${getLocalizedString("maxCharRequests")}.`}
                      </Typography>
                    }
                  </Grid>

                </Grid>
              </DialogContent>
              {
                isReadOnly &&
                <div style={{marginTop: 32}} />
              }
              {
                !isReadOnly && 
                <DialogActions>
                  <ButtonDialogAction
                    onClick={this.handleClose}
                    text={getLocalizedString("disagree")}
                  />
                  <ButtonDialogAction
                    type="submit"
                    isAccept
                    text={getLocalizedString("agree")}
                  />
                </DialogActions>
              }
              {
                this.state.userSelectionDialogIsOpen &&
                <UserSelectionDialog
                  open={this.state.userSelectionDialogIsOpen}
                  initialData={[values.colaborator]}
                  users={colaborators.map((colab) => {
                    return {
                      FOTOGRAFIA: colab["FOTOGRAFIA"],
                      name: colab.value,
                      key: colab.code,
                    }
                  })}
                  title={getLocalizedString("selectColabFire")}
                  selectedUsersTitle={getLocalizedString("selectedColab")}
                  handleSubmit={(value) => window.setFormValue("colaborator", value[0])}
                  handleClose={() => this.setState({ userSelectionDialogIsOpen: false })}
                  singleSelection
                />
              }
            </form>
          )}}        
        />        
      </>
    );
  }
}
export default NewDisengagementContent;
