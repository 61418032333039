import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import MyCertificatesAndReceiptsDialog from "@icarius-common/myCertificatesAndReceiptsDialog/components/index";
import QueriesDialog from "@icarius-pages/myPeople/components/dialog/queriesDialog";
import useMyPersonalData from "./useMyPersonalData";
import UserSummary from "./userSummary";
import UserForm from "./userForm";
import ChangeProfileImageDialog from "./changeProfileImageDialog";

const MyPersonalData = () => {

  const {
    isLoading,
    employee,
    userData,
    enableCertificates,
    enableReceipts,
    queries,
    kpiData,
    isLoadingQueries,
    isPhotoModificable,
    state,
    handlers,
  } = useMyPersonalData();

  return (
    <CommonPage
      isNotGridPage
      isLoading={isLoading || isLoadingQueries}
      title={getLocalizedString("myPersonalData")}
      audioName={"Datos personales"}
    >
      {
        Boolean(Object.keys(employee).length) &&
        <div id={"main-content"} style={{ padding: 50, paddingTop: 30, marginBottom: 0 }}>
          <UserSummary
            employee={employee}
            isPhotoModificable={isPhotoModificable}
            enableCertificates={enableCertificates}
            enableReceipts={enableReceipts}
            enableQueriesButton={Boolean(queries.length)}
            handleFirstButton={handlers.handleOpenMyCertificatesDialog}
            handleSecondButton={handlers.handleOpenMyReceiptsDialog}
            handleQueriesButton={handlers.handleOpenQueriesDialog}
            handleOpenChangeMyPersonalPhotoDialog={handlers.handleOpenChangeMyPersonalPhotoDialog}
          />
          <UserForm
            data={employee}
            handleUpdateEmployee={handlers.handleUpdatePersonalData}
          />
        </div>
      }
      {
        state.changeMyPersonalPhotoIsOpen &&
        <ChangeProfileImageDialog
          open={state.changeMyPersonalPhotoIsOpen}
          profileImage={employee["FOTOGRAFIA"]}
          handleClose={handlers.handleCloseChangeMyPersonalPhotoDialog}
          displayWarning
        />
      }
      {
        state.myCertificatesDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={state.myCertificatesDialogIsOpen}
          isCertificate={true}
          handleClose={handlers.handleCloseMyCertificatesDialog}
        />
      }
      {
        state.myReceiptsDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={state.myReceiptsDialogIsOpen}
          isCertificate={false}
          handleClose={handlers.handleCloseMyReceiptsDialog}
        />
      }
      {
        state.queriesDialogIsOpen &&
        <QueriesDialog
          open={state.queriesDialogIsOpen}
          employeeCode={userData.code}
          queries={queries}
          kpiData={kpiData}
          handleClose={handlers.handleCloseQueriesDialog}
        />
      }
    </CommonPage>
  )
}

export default MyPersonalData;