import React from "react";
import { Grid, Typography } from "@material-ui/core";

const DataContainer = ({ label, value }) => {
    return (
        <Grid container alignItems="center" xs={12} sm={4} item>
            <Grid item xs={12}>
                <Typography className="whiteText">
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    style={{
                        fontWeight: 500,
                        fontSize: '1.2rem',
                        textTransform: "uppercase",
                    }}
                    className="whiteText"
                >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default DataContainer;