import React from "react";
import { Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";

const CantCreateRequestMessage = ({ k }) => {
    return (
      <Typography
        style={{ paddingBottom: 15 }}
        className={"whiteText"}
        variant="h6"
        align="center"
        gutterBottom>
        {getLocalizedString("cantCreateNewRequest")}
      </Typography>
    );
  };
  
export default CantCreateRequestMessage;
